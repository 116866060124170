import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useStore } from 'app/context';
import React, { useEffect, useState } from 'react';
import { AgtErrungenschaftenVM } from 'app/stores/ui/agt.errungenschaften.ui.store';
import { AgenturErrungenschaftenGrid } from 'app/components/errungenschaften/AgenturErrungenschaften';
import { BnrErrungenschaften, BnrErrungenschaftenOverview } from 'app/components/errungenschaften/BnrErrungenschaften';
import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import routes from 'routes';

const AgtErrungenschaftenDebug = observer(() => {
	const { agtErrungenschaftenUiStore } = useStore();
	const [agtItems, setAgtItems] = useState<AgtErrungenschaftenVM[]>([]);

	useEffect(() => {
		console.log(` errungenschaften vm item count: ${agtErrungenschaftenUiStore.items.length}`);
		setAgtItems([]);
		if (agtErrungenschaftenUiStore.isLoaded) {
			setAgtItems(agtErrungenschaftenUiStore.items);
		}
	}, [agtErrungenschaftenUiStore.items, agtErrungenschaftenUiStore.isLoaded]);

	return (
		<>
			{agtItems.map((i) => {
				return (
					<span key={i.agt.agtId}>
						<hr />
						<strong>
							{i.agt.name} ({i.agt.agtId})<br />
						</strong>
						<AgenturErrungenschaftenGrid model={i} />
						<br />
					</span>
				);
			})}
			{agtErrungenschaftenUiStore.isLoaded && <div className="crawlErrungenschaftenAgtLoaded"> Loaded </div>}
		</>
	);
});

const SaveErrungenschaften = observer(() => {
	const { bnrErrungenschaftenUiStore, authStore } = useStore();
	const history = useHistory();

	const [saved, setSaved] = useState(false);
	useEffect(() => {
		bnrErrungenschaftenUiStore.save().then(() => {
			setSaved(true);
			console.log('saved');
		});
	}, [bnrErrungenschaftenUiStore]);

	const onLogout = () => {
		authStore.logout();
		history.push(routes.LOGIN.getPath());
	};

	return (
		<div>
			{saved && (
				<>
					<Button onClick={onLogout} className="button is-inverted has-icon is-light">
						<Icon iconClass="sign-out" faModifier="fal" />
						<span>Abmelden</span>
					</Button>
					<span className={'crawlErrungenschaftenSaved'}>saved!!</span>
				</>
			)}
		</div>
	);
});

const ErrungenschaftenContainer = observer((props: RouteComponentProps) => {
	return (
		<BaseContainer sideMenu={<></>} navbar={<div>Errungenschaften</div>}>
			<SaveErrungenschaften />
			<BnrErrungenschaften />
			<BnrErrungenschaftenOverview />
			<br />
			<br />
			<strong>Agts</strong>
			<br />
			<AgtErrungenschaftenDebug />
		</BaseContainer>
	);
});

export default ErrungenschaftenContainer;

export const ErrungenschaftenCrawlContainer = observer((props: RouteComponentProps) => {

	return (
		<BaseContainer sideMenu={<></>} navbar={<div>Errungenschaften</div>}>
			<SaveErrungenschaften />
		</BaseContainer>
	);
});
