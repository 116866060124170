import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { autorun, runInAction } from 'mobx';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { Modal } from '../Modal';
import { Table } from '../table/table';
import { TableModel, TableRow } from '../table/table.model';
import { Icon } from 'app/components/common/Icon';
import { UserModel } from 'app/models/core/user.model';

interface IBnrSelectorProps {
	selectedBnr?: UserModel;
	onSelect: (agt?: UserModel) => void;
	defaultText?: string;
}

export const BnrSelector = observer((props: IBnrSelectorProps) => {
	const { uiStore } = useStore();
	const [bnr, setBnr] = useState<UserModel | undefined>();
	const defaultText = props.defaultText ? props.defaultText : 'BNR';
	const [text, setText] = useState<string>('');

	useEffect(() => {
		setBnr(props.selectedBnr);
		if (bnr) {
			setText(`${bnr.name} (${bnr.steartText})`);
		} else {
			setText(defaultText);
		}
	}, [text, bnr, props.selectedBnr, defaultText]);
	const modalId = uiStore.modalIds.bnrSelect;

	const selectBnr = () => {
		runInAction(() => {
			uiStore.showModal(modalId);
		});
	};
	const onReset = () => {
		runInAction(() => {
			uiStore.hideModal(modalId);
			setBnr(undefined);
			props.onSelect(undefined);
		});
	};

	const onSelect = (bnr?: UserModel) => {
		runInAction(() => {
			uiStore.hideModal(modalId);
			setBnr(bnr);
			props.onSelect(bnr);
		});
	};

	return (
		<>
			<div className={`agt-selector  ${bnr ? 'is-active' : ''}`}>
				<Button className={`button is-header-button is-agt-selector`} onClick={selectBnr}>
					<span className="agt-name">{text}</span>
					<Icon iconClass="caret-down" />
				</Button>
				{bnr && (
					<Button className="button is-header-button is-agt-select-reset" onClick={onReset}>
						<Icon iconClass="times" />
					</Button>
				)}
			</div>

			<Modal modifier="is-table-modal" title="Betreuerauswahl" modalId={modalId}>
				<BnrSelectorList onSelect={onSelect} />
			</Modal>
		</>
	);
});

const BnrSelectorList = observer((props: IBnrSelectorProps) => {
	const { agtUserUiStore } = useStore();

	const tm = new TableModel<UserModel, number>();
	const cols = [
		{
			label: 'Name',
			path: 'name',
		},
		{
			label: 'Steart',
			path: 'steartText',
		},
	];
	tm.showHeader = false;
	tm.setCols(cols);
	tm.sortBy = 'name';
	tm.idProperty = 'bnrId';
	tm.idType = 'number';
	agtUserUiStore.load().then((res) => {
		tm.setRowData(agtUserUiStore.items);
	});

	tm.onRowClick = (row: TableRow<UserModel>) => {
		props.onSelect(row.data);
	};

	useEffect(() => {
		return autorun(() => {
			if (props.selectedBnr) {
				tm.setSelectedId(props.selectedBnr.bnrId);
			} else {
				tm.setSelectedId(undefined);
			}
		});
	});
	return (
		<div className="agt-selector-list">
			<Table tm={tm}></Table>
		</div>
	);
});
