import { Icon } from 'app/components/common/Icon';
import { SelectInput } from 'app/components/common/Select';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { TableModel, ITableColumn } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { ErrungenschaftState } from 'app/models/errungenshaften/errungenschaften.model';
import { AgtErrungenschaftenVM } from 'app/stores/ui/agt.errungenschaften.ui.store';
import { observer } from 'mobx-react';
import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';

interface IAgtErrungenschaftState {
	ekey: string;
	vm: AgtErrungenschaftenVM;
}
const AgtErrungenschaftState = observer((props: IAgtErrungenschaftState) => {
	const er = props.vm.items.find((y) => y.key === props.ekey);
	if (!er) {
		return <>?</>;
	}
	return (
		<div>
			{er.state !== ErrungenschaftState.off && (
				<div className={`errungenshaft-state-icon is-${er.state} is-${er.valType}`}>
					<Icon iconClass="check" faModifier="fas" />
					{er.isNewInCurrentKw && <div className="tag is-small">NEU</div>}
				</div>
			)}
		</div>
	);
});

interface IAgtErrungenschaftStateKW extends IAgtErrungenschaftState {
	kw: string;
}

export const AgtErrungenschaftenList = observer(() => {
	const { agtErrungenschaftenUiStore, agenturListUiStore } = useStore();
	const history = useHistory();

	const [loaded, setLoadad] = useState<boolean>(false);

	const [kw, setKw] = useState<string>(agtErrungenschaftenUiStore.currentKw);

	const tm = useMemo(() => {
		const tm = new TableModel<AgtErrungenschaftenVM, number>();

		let cols: ITableColumn<AgtErrungenschaftenVM, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			},
		];

		agtErrungenschaftenUiStore.agtErrungenschaftenDefinition.forEach((e, i) => {
			cols.push({
				label: e.modal.title,
				path: 'items[' + i + '].state',

				sortBy: (data: AgtErrungenschaftenVM) => {
					const x = data.getItemByKey(e.key);
					return x ? x.stateForSort : 0;
				},
				render: (data) => {
					return (
						<>
							<AgtErrungenschaftState vm={data.data} ekey={e.key} />{' '}
						</>
					);
				},
			});
		});
		tm.onRowClick = (row) => {
			history.push(`/agentur/${row.data.agt.agtId}`);
		};
		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		return tm;
	}, [agtErrungenschaftenUiStore.agtErrungenschaftenDefinition, history]);

	useEffect(() => {
		agtErrungenschaftenUiStore.load().then(() => {
			const items = agenturListUiStore.applyFilterOnAgt(agtErrungenschaftenUiStore.items) as AgtErrungenschaftenVM[];

			items.forEach((i) => {});

			tm.setRowData(items);
			setLoadad(true);
		});
	}, [
		tm,
		setLoadad,
		agtErrungenschaftenUiStore,
		agtErrungenschaftenUiStore.items,
		agtErrungenschaftenUiStore.currentKw,
		agenturListUiStore.filterHasChanged,
		agenturListUiStore,
	]);

	const onKwChange = (name: string, obj: any) => {
		setKw(obj.value);
		agtErrungenschaftenUiStore.setCurrentKw(obj.value);
	};

	return (
		<>
			{loaded && (
				<>
					<div className="table-container pad-bottom-3rem">
						<div className="grid is-col-auto align-center justify-start pad-1rem">
							<strong>Woche: </strong>
							<SelectInput name="kwMin" emptyText="KW" value={kw} onChange={onKwChange} opts={agtErrungenschaftenUiStore.kwOpts} isClearable={false} />
						</div>
						<Table tm={tm} />
					</div>
				</>
			)}
		</>
	);
});
