import { useStore } from 'app/context';
import { formatBM, formatDate, formatKwArray } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

export const DatenStandBj = observer(() => {
	const { agtPosBjStore } = useStore();

	return <>{agtPosBjStore.datenStand && <>Stand: {formatBM(agtPosBjStore.datenStand)}</>}</>;
});

interface IDatenStand {
	year: number;
}

export const DatenStandPosZiel = observer((props: IDatenStand) => {
	const { agtZielStore, session } = useStore();
	const [datenStand, setDatenStand] = useState<Date>();

	useEffect(() => {
		const d = agtZielStore.getDatenStandByYear(props.year);
		setDatenStand(d);
	}, [props.year, agtZielStore]);

	if (datenStand && session.currentUser?.isMulti) {
		return <>{<>Stand: {formatDate(datenStand, 'MMMM YYYY')}</>}</>;
	}

	return <>{datenStand && <>Stand: {formatDate(datenStand)}</>}</>;
});

export const DatenStandDigiFaktor = observer(() => {
	const { agtDigigFaktorStore } = useStore();

	return <>{agtDigigFaktorStore.datenStand && <>Stand: {formatBM(agtDigigFaktorStore.datenStand)}</>}</>;
});

export const DatenStandAkumaPrime = observer(() => {
	const { agtAkumaPrimeUiStore } = useStore();

	return <>{agtAkumaPrimeUiStore.currentKWs && <>Stand: KW {formatKwArray(agtAkumaPrimeUiStore.currentKWs)}</>}</>;
});

export const DatenStandDigitalReport = observer(() => {
	return <>{<>Stand: {formatDate(new Date('2024-09-30'))}</>}</>;
});
