import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { CoronaCheckFormItem } from 'app/models/corona/corona.check.form.item';
import { LeadnowCheckFormItem } from 'app/models/leadnowcheck/leadnow.check.form.item';
import { observer } from 'mobx-react';

const openElearningEmail = (agt?: AgenturModel) => {
	let eSubject = encodeURI('Link zum e-Training Lead Management');
	let eBody = encodeURI(
		'Liebe(r) ,' +
			'\n' +
			'\n' +
			'zur Freischaltung Ihrer Agentur in Lead.NOW ist das erfolgreiche Absolvieren des e-Trainings Lead Management durch Sie als Agenturinhaber erforderlich.' +
			'\n\n' +
			'Hierzu sende ich Ihnen den Link zur Akademie.' +
			'\n' +
			'https://akademien.allianz.de/app.html#!/training/937604636' +
			'\n\n' +
			'Bitte nehmen Sie sich für das e-Training 45 Minuten Zeit und senden Ihr Zertifikat im Anschluss an den Postkorb leadmanagement@allianz.de. ' +
			'\n\n' +
			'Sobald die Freischaltung Ihrer Vertreternummer in Lead.NOW erfolgt ist, können Sie mit der ersten Leadbuchung beginnen. ' +
			'\n\n' +
			' ' +
			'\n\n',
	);

	let emailAgt = '';
	if (agt && agt.email) {
		emailAgt = ';' + agt.email;
	}

	let email = `mailto:?to=${emailAgt}&subject=${eSubject}&body=${eBody}`;
	window.location.href = email;
};

interface IFieldRow {
	formItem: LeadnowCheckFormItem | CoronaCheckFormItem;
	index: number;
}
export const CheckActionButton = observer((props: IFieldRow) => {
	const { agenturStore } = useStore();
	const actionButton = props.formItem.actionButton;
	if (!actionButton) {
		return <></>;
	}

	const onClick = () => {
		agenturStore.findById(props.formItem.data.agtId).then((agt) => {
			switch (props.formItem.actionButton!.buttonAction) {
				case 'openElearningEmail':
					openElearningEmail(agt);
					break;
				default:
					console.warn('props.formItem.actionButton!.buttonAction', props.formItem.actionButton!.buttonAction, 'not implemented');
			}
		});
	};

	return (
		<Button className="button is-primary is-inverted is-small no-print" onClick={onClick}>
			{actionButton.buttonIcon && <Icon iconClass={actionButton.buttonIcon} />}
			<span>{actionButton.buttonText}</span>
		</Button>
	);
});
