import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { KvAgtMsLiteModel } from 'app/models/kv.agt.ms.lite.model';

export class KvAgtGoogleProfileStore extends BaseCachedEntityStore<KvAgtMsLiteModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agentur_ms/googleprofile';

	createInstances(opts: any): KvAgtMsLiteModel {
		return new KvAgtMsLiteModel(opts);
	}
}
