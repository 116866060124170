import { makeObservable, observable } from 'mobx';
import _ from 'lodash';
import { SessionStore } from './session.store';
import { KvAgtModel } from 'app/models/kv.agt.model';
import { KvAgtBaseStore } from './kv.agt.base.store';

export class DigitalreportModel {
	constructor(item: KvAgtModel) {

		this.agtId = item.agtId;
		this.bnrId = item.bnrId;

		const j = JSON.parse(item.valueString);

		this.anzKunden = j.anzKunden ? j.anzKunden : 0


		this.prozDigiId = j.prozDigiId ? j.prozDigiId * 100 : 0;
		this.prozMaz = j.prozMaz ? j.prozMaz * 100 : 0;
		this.prozWewe = j.prozWewe ? j.prozWewe * 100 : 0;

		this.anzKunden18 = j.anzKunden18 ? j.anzKunden18 : 0;
		this.prozDigiId18 = j.prozDigiId18 ? j.prozDigiId18 * 100 : 0;
		this.prozMaz18 = j.prozMaz18 ? j.prozMaz18 * 100 : 0;
		this.prozWewe18 = j.prozWewe18 ? j.prozWewe18 * 100 : 0;

		this.anzKundenNeu = j.anzKundenNeu ? j.anzKundenNeu : 0;
		this.prozDigiIdNeu = j.prozDigiIdNeu ? j.prozDigiIdNeu * 100 : 0;
		this.prozMazNeu = j.prozMazNeu ? j.prozMazNeu * 100 : 0;
		this.prozWeweNeu = j.prozWeweNeu ? j.prozWeweNeu * 100 : 0;

		this.anzMaz = j.anzMaz ? j.anzMaz : 0
		this.anzMaz18 = j.anzMaz18 ? j.anzMaz18 : 0;
		this.anzMazNeu = j.anzMazNeu ? j.anzMazNeu : 0;
	}

	agtId: number;
	bnrId: number;

	anzKunden: number = 0;
	prozDigiId: number = 0;
	prozMaz: number = 0;
	prozWewe: number = 0;

	anzKunden18: number = 0;
	prozDigiId18: number = 0;
	prozMaz18: number = 0;
	prozWewe18: number = 0;

	anzKundenNeu: number = 0;
	prozDigiIdNeu: number = 0;
	prozMazNeu: number = 0;
	prozWeweNeu: number = 0;

	anzMaz: number = 0;
	anzMaz18: number = 0;
	anzMazNeu: number = 0;
}

export class KvAgtDigitalreportStore {
	kvAgtStore: KvAgtBaseStore;

	constructor(session: SessionStore) {
		this.kvAgtStore = new KvAgtBaseStore(session);
		this.kvAgtStore.apiPath = 'api/kv_agt/digitalreport';
	}

	findByAgtId(agtId: number) {
		return this.items.find((i) => i.agtId === agtId);
	}

	items: DigitalreportModel[] = [];

	async load() {
		if (this.items.length > 0) {
			return this.items;
		}
		const data = await this.kvAgtStore.findAll();
		this.items = data.map((item) => new DigitalreportModel(item));
		return this.items;
	}
}
