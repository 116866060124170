import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import React, { useEffect, useMemo, useState } from 'react';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { Table } from 'app/components/table/table';
import { formatDate } from 'app/utils';
import { WidgetNavigate } from '../common/WidgetNavigate';
import { PersonalVM } from 'app/models/personal/personal.vm';

export const JubelTable = observer(() => {
	const [upComeCount, setUpComeCount] = useState(0);

	const { agenturUiStore, personalUiStore } = useStore();
	const agt = agenturUiStore.current;

	const tm = useMemo(() => {
		const tm = new TableModel<PersonalVM, number>();
		const cols = [
			{
				label: 'Jubiläum',
				path: 'anniversaryDate',
				sortBy: 'beitrittDatMonthDay', // same sort as above
				format: formatDate,
			},
			{
				label: 'Name',
				path: 'nachname',
				render: (row: TableRow<PersonalVM>) => {
					return (
						<>
							{row.data.fullName} ({row.data.stellenBezeichnung}){' '}
						</>
					);
				},
			},
			{
				label: 'Jahre',
				path: 'workingYears',
				// render: (row: TableRow<PersonalVM>) => {
				// 	return (
				// 		<>
				// 			{row.data.fullName} ({row.data.hasAnniversary}){' '}
				// 		</>
				// 	);
				// },
			},
			{
				label: 'Tage zum Jubiläum',
				path: 'nextAnnivesaryInDays',
				// render: (row: TableRow<PersonalVM>) => {
				// 	return (
				// 		<>
				// 			{row.data.fullName} ({row.data.hasAnniversary}){' '}
				// 		</>
				// 	);
				// },
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'gebDatMonthDay';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		personalUiStore.load().then(() => {
			let res = personalUiStore.items;
			res = res.filter((p) => p.hasUpcomingAnniversary);
			res = res.filter((p) => p.workingYears > 1 && p.workingYears < 99);
			res = res.filter((p) => p.hasAnniversary);
			if (agt) {
				res = res.filter((p) => p.agtId === agt.agtId);
			}
			tm.setRowData(res);
			setUpComeCount(res.length);
		});
	}, [tm, personalUiStore, agt, setUpComeCount, upComeCount]);

	return <>{upComeCount > 0 ? <Table tm={tm}></Table> : <div className="pad-1rem">Keine anstehenden Jubiläen</div>}</>;
});

export const JubelWidget = observer((props: IWidgetConsumer) => {
	const [upComeCount, setUpComeCount] = useState(0);

	const { agenturUiStore, personalUiStore } = useStore();
	const agt = agenturUiStore.current;

	useEffect(() => {
		personalUiStore.load().then(() => {
			let res = personalUiStore.items;
			res = res.filter((p) => p.hasAnniversary);
			if (agt) {
				res = res.filter((p) => p.agtId === agt.agtId);
			}
			// tm.setRowData(res);
			setUpComeCount(res.length);
		});
	}, [personalUiStore, agt, setUpComeCount, upComeCount]);

	return (
		<Widget
			title={'Anstehende Jubiläen'}
			modifier={upComeCount > 0 ? 'is-paddingless' : ''}
			action={<WidgetNavigate text={'Alle'} path={'/berichte/personal/jubilaen'} />}
			widgetId={props.widgetKey}
		>
			<JubelTable />
		</Widget>
	);
});
