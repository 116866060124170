import { KvAgtMsBaseStore } from '../kv.agt.base.store';
import { SessionStore } from '../session.store';
import _ from 'lodash';
import { computed, runInAction } from 'mobx';
import moment from 'moment';
import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import colors from 'assets/scss/colors.module.scss';
import { BnrStatsStore } from '../bnr.stats.store';
import { KvAgtMsModel } from 'app/models/kv.agt.ms.model';

export interface IDigiFaktorSchema {
	label: string;
	title: string;
	path: string;
	domain: [number, number];
}
export const DigiFaktorSchema: IDigiFaktorSchema[] = [
	{
		label: 'DF',
		title: 'Digitalisierungfaktor',
		path: 'DF',
		domain: [0, 100],
	},
	{
		label: 'MVTHP',
		title: 'Monatliche Visits Homepage',
		path: 'MVTHP',
		domain: [0, 35],
	},
	{
		label: 'Facebook',
		title: 'Facebook',
		path: 'FACEBOOK',
		domain: [0, 35],
	},
	{
		label: 'GOOGLE',
		title: 'Google SEO Paket',
		path: 'GOOGLE_SEO',
		domain: [0, 10],
	},
	{
		label: 'BANNER',
		path: 'BANNER',
		title: 'Online Werbebanner',
		domain: [0, 5],
	},
	{
		label: 'XING',
		title: 'XING',
		path: 'XING',
		domain: [0, 20],
	},
	{
		label: 'OT',
		path: 'OT',
		title: 'Online Terminierung',
		domain: [0, 20],
	},
	{
		label: 'MAZ',
		title: 'MAZ',
		path: 'MAZ',
		domain: [0, 10],
	},

	{
		label: 'ESIGN',
		title: 'eSign',
		path: 'ESIGN',
		domain: [0, 5],
	},
	{
		label: 'EKOMI',
		title: 'eKomi',
		path: 'EKOMI',
		domain: [0, 40],
	},
	{
		label: 'GTM',
		title: 'Onlineberatung',
		path: 'GTM',
		domain: [0, 10],
	},
	{
		label: 'WA',
		title: 'Whatsapp',
		path: 'WA',
		domain: [0, 20],
	},
];

export class AgtDigiVM implements IDigiPositionen {
	constructor(agt: AgenturModel, items: KvAgtMsModel[]) {
		this.agt = agt;
		if (items.length === 0) {
			this.bm = 0;
			this.agtId = 0;
			this.bnrId = 0;
			return;
		}
		this.bm = items[0].bm;
		this.agtId = items[0].agtId;
		this.bnrId = items[0].bnrId;

		this.DF = KvAgtMsModel.getNumber(items, 'DF');
		this.GTM = KvAgtMsModel.getNumber(items, 'GTM');
		this.WA = KvAgtMsModel.getNumber(items, 'WA');
		this.MAZ = KvAgtMsModel.getNumber(items, 'MAZ');
		this.ESIGN = KvAgtMsModel.getNumber(items, 'ESIGN');
		this.OT = KvAgtMsModel.getNumber(items, 'OT');
		this.EKOMI = KvAgtMsModel.getNumber(items, 'EKOMI');
		this.BANNER = KvAgtMsModel.getNumber(items, 'BANNER');
		this.XING = KvAgtMsModel.getNumber(items, 'XING');
		this.FACEBOOK = KvAgtMsModel.getNumber(items, 'FACEBOOK');
		this.GOOGLE_SEO = KvAgtMsModel.getNumber(items, 'GOOGLE_SEO');
		this.MVTHP = KvAgtMsModel.getNumber(items, 'MVTHP');
	}

	bm: number;
	@computed
	get bmLabel() {
		return moment(this.bm).format('YYYY-MM');
	}
	@computed
	get bmDate() {
		return moment(this.bm + '01').toDate();
	}

	agtId: number;
	agt: AgenturModel;
	bnrId: number;
	DF?: number;
	GTM?: number;
	WA?: number;
	MAZ?: number;
	ESIGN?: number;
	OT?: number;
	EKOMI?: number;
	BANNER?: number;
	XING?: number;
	FACEBOOK?: number;
	GOOGLE_SEO?: number;
	MVTHP?: number;
}

interface IDigiPositionen {
	DF?: number;
	GTM?: number;
	WA?: number;
	MAZ?: number;
	ESIGN?: number;
	OT?: number;
	EKOMI?: number;
	BANNER?: number;
	XING?: number;
	FACEBOOK?: number;
	GOOGLE_SEO?: number;
	MVTHP?: number;
}

export interface IBnrDataItem {
	value: number;
	label: string;
	fill: string;
}

export class AgtDigigFaktorUiStore {
	kvAgtMsStore: KvAgtMsBaseStore;
	agenturStore: AgenturStore;
	bnrStatsStore: BnrStatsStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, bnrStatsStore: BnrStatsStore) {
		this.kvAgtMsStore = new KvAgtMsBaseStore(session);
		this.kvAgtMsStore.disableFindForStearts = [123];
		this.agenturStore = agenturStore;
		this.bnrStatsStore = bnrStatsStore;
		this.kvAgtMsStore.apiPath = 'api/kv_agentur_ms/digitialisierungsfaktor';
	}
	onLogout() {
		this.kvAgtMsStore.onLogout();
		this.agenturStore.onLogout();
		this.bnrStatsStore.onLogout();
		this.kvAgtMsStore.onLogout();
	}

	filterByAgtId(agtId: number) {
		return this.items.filter((i) => i.agtId === agtId);
	}

	async findCurrentByAgtId(agtId: number) {
		const items = await this.findAllCurrent();
		return items.find((i) => i.agtId === agtId);
	}

	async findAllCurrent() {
		await this.load();
		const curr = moment(this.currentBM);
		return this.items.filter((i) => moment(i.bmDate).isSame(curr, 'd') && i.agt.supportsDigiFaktor);
	}

	items: AgtDigiVM[] = [];

	bms: Date[] = [];

	@computed
	get currentBM() {
		return this.bms[this.bms.length - 1];
	}

	getAvgByPath(p: string): number {
		return (this.bnrAvg as any)[p];
	}

	bnrAvg: IDigiPositionen = {};

	async calcAvgs() {
		console.time('digifactor calcAvgs');

		const items = await this.findAllCurrent();

		const avg: IDigiPositionen = {};
		DigiFaktorSchema.forEach((ds) => {
			const p = ds.path;
			(avg as any)[p] = Math.round(_.meanBy(items, (i) => _.get(i, p)));
		});

		runInAction(() => {
			this.bnrAvg = avg;
		});
		console.timeEnd('digifactor calcAvgs');
	}

	async getBnrOverview() {
		console.time('getBnrOverview');

		if (!this.bnrStatsStore.loaded) {
			await this.bnrStatsStore.load();
		}
		if (!this.bnrAvg) {
			await this.calcAvgs();
		}

		const stats = this.bnrStatsStore.stats;
		const items: IBnrDataItem[] = [];
		if (!this.bnrAvg) {
			console.warn('bnrAvg is undefined');
			return items;
		}
		if (!stats) {
			console.warn('no stats for bnroverview');
			return items;
		}
		items.push({ value: this.bnrAvg.DF!, label: 'BNR', fill: colors.blue });
		items.push({ value: stats.GS_DF_Avg, label: 'GS ' + stats.GS, fill: colors.purple });
		items.push({ value: stats.VD_DF_Avg, label: 'VD ' + stats.VD, fill: colors.green });
		items.push({ value: stats.ABV_DF_Avg, label: 'ABV', fill: colors.red });
		console.timeEnd('getBnrOverview');

		return items;
	}

	datenStand?: number; // bm

	// @observable loaded = false;

	loadingPromise?: Promise<any>;
	loadingPromiseResolver?: any;

	async load() {

		console.log('digifaktor loading disabled');
		return;

		// if (this.loadingPromise) {
		// 	await this.loadingPromise;
		// 	return;
		// }

		// this.loadingPromise = new Promise((resolve) => {
		// 	this.loadingPromiseResolver = resolve;
		// });
		// const data = await this.kvAgtMsStore.findAll();
		// const agts = await this.agenturStore.findAll();

		// const bms = _.uniq(_.map(data, 'bm')).sort();
		// this.bms = bms.map((bm) => moment(bm + '01').toDate());
		// const groupedByAgt = _.chain(data)
		// 	.groupBy('agtId')
		// 	.map((value, key) => {
		// 		return value;
		// 	})
		// 	.value();

		// this.items = [];

		// groupedByAgt.forEach((items) => {
		// 	const agt = agts.find((a) => a.agtId === items[0].agtId);
		// 	if (!agt) {
		// 		// console.warn('agt.digifaktorui.store.load() could not find agt with id' + items[0].agtId);
		// 		return;
		// 	}
		// 	const byBM = _.chain(items)
		// 		.groupBy('bm')
		// 		.map((value, key) => {
		// 			return value;
		// 		})
		// 		.value();
		// 	const bms = byBM.map((bmItems) => new AgtDigiVM(agt!, bmItems));
		// 	this.items = this.items.concat(bms);
		// });
		// const max = _.maxBy(data, 'bm');
		// if (max) {
		// 	this.datenStand = max.bm;
		// }
		// if (this.loadingPromiseResolver) {
		// 	this.loadingPromiseResolver();
		// }

		// await this.calcAvgs();
	}
}
