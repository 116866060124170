import { SelectInput } from 'app/components/common/Select';
import { Table } from 'app/components/table/table';
import { TableModel, ITableColumn } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { ErrungenschaftSummaryModel } from 'app/models/errungenshaften/errungenschaften.model';
import { formatKw } from 'app/utils';
import { observer } from 'mobx-react';
import { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const AgtErrungenschaftenOverview = observer(() => {
	const { agtErrungenschaftenUiStore } = useStore();
	const [kw, setKw] = useState<string>(agtErrungenschaftenUiStore.currentKw);
	const history = useHistory();

	const tm = useMemo(() => {
		const tm = new TableModel<ErrungenschaftSummaryModel, number>();

		let cols: ITableColumn<ErrungenschaftSummaryModel, number>[] = [
			{
				label: 'Errungenschaft',
				path: 'errungenschaft.key',
				render: (row) => {
					return <span>{row.data.errungenschaft.modal.title}</span>;
				},
			},
			{
				label: 'Gesamt',
				path: 'count',
			},
			{
				label: `Woche: ${formatKw(parseInt(kw))}`,
				path: 'countCurrentKw',
			},
		];
		tm.onRowClick = (row) => {
			let path = row.data.errungenschaft.pathToReport;
			if (path === 'planung') {
				path = 'agentur';
			}
			history.push(`/${path}`);
		};
		tm.sortBy = 'errungenschaft.key';
		tm.setCols(cols);
		return tm;
	}, [history, kw]);

	useEffect(() => {
		agtErrungenschaftenUiStore.load().then(() => {
			tm.setRowData(agtErrungenschaftenUiStore.agtErrungenschaftenSummaryItems);
		});
	}, [tm, agtErrungenschaftenUiStore, agtErrungenschaftenUiStore.items, agtErrungenschaftenUiStore.agtErrungenschaftenSummaryItems]);

	const onKwChange = (name: string, obj: any) => {
		setKw(obj.value);
		agtErrungenschaftenUiStore.setCurrentKw(obj.value);
	};

	return (
		<>
			<div className="table-container pad-bottom-3rem">
				<div className="grid is-col-auto align-center justify-start pad-1rem">
					<strong>Woche: </strong>
					<SelectInput name="kwMin" emptyText="KW" value={kw} onChange={onKwChange} opts={agtErrungenschaftenUiStore.kwOpts} isClearable={false} />
				</div>
				<Table tm={tm} />
			</div>
		</>
	);
});
