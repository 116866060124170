import { PersonalVM } from 'app/models/personal/personal.vm';
import { runInAction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { PersonalCustomStore } from '../personal.custom.store';
import { PersonalTeilhaberStore } from '../personal.teilhaber.store';
import { PersonalZweitverkaeuferStore } from '../personal.zweitverkauefer.store';
import { SessionStore } from '../session.store';
import { BaseUiStore } from './base.ui.store';

export class PersonalUiStore extends BaseUiStore<PersonalVM> {
	name = 'PersonalUiStore';
	constructor(
		session: SessionStore,
		customPersonalStore: PersonalCustomStore,
		personalTeilhaberStore: PersonalTeilhaberStore,
		personalZweitverkaeuferStore: PersonalZweitverkaeuferStore,
		agenturStore: AgenturStore,
	) {
		super();
		this.session = session;
		this.custom = customPersonalStore;
		this.teilhaber = personalTeilhaberStore;
		this.zweitverkauefer = personalZweitverkaeuferStore;
		this.agenturStore = agenturStore;
	}

	session: SessionStore;
	custom: PersonalCustomStore;
	teilhaber: PersonalTeilhaberStore;
	zweitverkauefer: PersonalZweitverkaeuferStore;
	agenturStore: AgenturStore;



	async reloadAll() {
		this.clearCache();
		this.custom.clearCache();
		await this.load();
		return this.items;
	}

	async _baseLoad() {
		const p = await Promise.all([this.custom.findAll(), this.teilhaber.findAll(), this.zweitverkauefer.findAll()]);
		const custom = p[0];
		const teilhaber = p[1];
		const zweitverkauefer = p[2];

		let res: PersonalVM[] = [];
		res = res.concat(custom.map((p) => p.toPersonalModel()));
		res = res.concat(teilhaber.map((p) => p.toPersonalModel()));
		res = res.concat(zweitverkauefer.map((p) => p.toPersonalModel()));
		const agts = await this.agenturStore.findAll();
		const canSeeGeburtstage = this.session.currentUser!.isVbl || this.session.currentUser!.isLeiter;

		res.forEach((p) => {
			runInAction(() => {
				const agt = agts.find((a) => a.agtId === p.agtId);
				if (agt) {
					p.agt = agt;
				} else {
				}
				if (!canSeeGeburtstage && !p.isEditable) {
					const s = p.stellenBezeichnung.toUpperCase();
					if (s.indexOf('GV') >= 0 || s.indexOf('HV') >= 0) {
						p.canShowGeburtstag = true;
					} else {
						p.canShowGeburtstag = false;
					}
				}
			});
		});
		runInAction(() => {
			this.items = res;
		})
	}

	async findByAgtId(agtId: number) {
		await this.load();
		return this.items.filter((i) => i.agtId === agtId);
	}

	async findById(id: string) {
		await this.load();
		return this.items.find((p) => p.id === id);
	}

	onLogout() {
		this.custom.onLogout();
		this.teilhaber.onLogout();
		this.zweitverkauefer.onLogout();
		this.agenturStore.onLogout();
	}
}
