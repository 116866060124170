import _ from 'lodash';
import { action, computed, IObservableArray, observable, runInAction } from 'mobx';
import moment from 'moment';
import { IResponse } from './base.api.store';
import { BaseEntityStore } from './base.entity.store';
import Config from 'Config';

class CacheItem<T> {
	constructor(cacheKey: string, item?: T) {
		this.cacheKey = cacheKey;
		this.item = item;
		this.loadingPromise = new Promise((resolve) => {
			this._resolver = resolve;
		});
	}
	markAsLoaded = () => {
		if (this._resolver) {
			this._resolver();
		}
	};
	_resolver: any;
	cacheKey: string;
	item?: T;
	loadingPromise: Promise<any>;
}

export class BaseCachedEntityStore<T> extends BaseEntityStore<T> {
	createInstances(opts: any): T {
		throw new Error('create not implemented');
	}

	@observable
	_items: IObservableArray<T> = observable([]);

	async findById(id: number | string) {
		const items = await this.findAll();
		return items.find((d) => (d as any)[this.idProp] === id);
	}

	cache: CacheItem<T>[] = [];
	async getById(id: number | string) {
		let cacheItem = this.cache.find((c) => c.cacheKey === id.toString());
		if (cacheItem) {
			await cacheItem.loadingPromise;
			return cacheItem.item;
		}
		cacheItem = new CacheItem(id.toString());

		this.cache.push(cacheItem);
		try {
			const res = await this.get(id.toString());
			if (res && res.data) {
				const instance = this.createInstances(res.data);
				cacheItem.item = instance;
				cacheItem.markAsLoaded();
				return instance;
			} else {
				if (res) {
					console.warn(res);
				}
				this.cache = this.cache.filter((c) => c.cacheKey !== id.toString());
				cacheItem.markAsLoaded();
			}
		} catch (ex) {
			console.error(ex);
			cacheItem.markAsLoaded();
			this.cache = this.cache.filter((c) => c.cacheKey !== id.toString());
		}
	}

	async findAll(): Promise<T[]> {
		return this.__findAll();
	}

	@observable
	loadingAllPromise: Promise<IResponse> | undefined = undefined;
	@observable loaded: boolean = false;
	async __findAll(): Promise<T[]> {
		if (this.disableFind) {
			return [];
		}
		if (this.loadingAllPromise) {
			await this.loadingAllPromise;
		}
		if (this.loaded) {
			return this._items;
		}

		this.loadingAllPromise = this.get('');
		const res = await this.loadingAllPromise;
		if (!res.data) {
			this.loadingAllPromise = undefined;
			this._items.clear();
			return this._items;
		}
		const startInstances = new Date();
		runInAction(() => {
			// console.time(this.apiPath + ' create instances');
			const items = res.data.map((d: any) => this.createInstances(d));
			// console.timeEnd(timerName);

			this._items.replace(items);
			this.loaded = true;
			this.loadingAllPromise = undefined;
		});
		this.setDatenStandByYear(this._items);
		if (Config.logShowApiCreateInstances) {
			const end = new Date().getTime() - startInstances.getTime();
			console.log('API INSTANCES ' + this.apiName + ' ' + end + ' ms (' + this._items.length + ')');
		}
		return this._items;
	}

	@action
	clearCache() {
		this.loaded = false;
		this.loadingAllPromise = undefined;
	}

	async save(data: T) {
		this.loaded = false;
		return this._save(data);
	}

	onLogout() {
		this.loaded = false;
		this._items.clear();
		this.cache = [];
	}

	datenStandByYear: DatenStand[] = [];

	datenStandProp?: string;
	setDatenStandByYear(data: T[]) {
		if (!this.datenStandProp) {
			return;
		}
		this.datenStandByYear = [];
		let dates = _.map(data, this.datenStandProp);
		dates = dates.filter((date, i, self) => {
			return self.findIndex((d) => d.getTime() === date.getTime()) === i;
		});
		dates.forEach((d) => {
			this.datenStandByYear.push(new DatenStand(d));
		});
	}

	getDatenStandByYear(year: number) {
		const s = this.datenStandByYear.find((d) => d.year === year);
		if (s) {
			return s.date;
		}
		return new Date();
	}
}

class DatenStand {
	constructor(d: Date) {
		this.date = d;
		this.mom = moment(d);
	}
	date: Date;
	mom: moment.Moment;

	@computed
	get year() {
		return this.mom.year();
	}

	@computed
	get month() {
		return this.mom.month() + 1;
	}
}
