import { Icon } from 'app/components/common/Icon';
import { SelectInput } from 'app/components/common/Select';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtAkumaPlanerVM, AkumaPlanerArtSchema } from 'app/stores/ui/agt.akumaplaner.ui.store';
import { formatNum } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { AkumaPlanerSankeyChart } from './AkumaPlanerSankeyChart';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { AkumaPlanerLegend } from './AkumaPlanerLegend';

export const AkumaOnePager = observer(() => {
	return (
		<>
			<a href={'AKUMA_Booklet.pdf'} target="_blank" rel="noreferrer" className="button is-primary is-small has-icon is-inverted">
				<span>AKUMA Booklet</span> <Icon iconClass="external-link-square" />
			</a>
		</>
	);
});

export const AkumaPlanerOptions = observer(() => {
	const { agtAkumaPlanerUiStore } = useStore();

	const onMinChange = (name: string, opt: any) => {
		runInAction(() => {
			agtAkumaPlanerUiStore.minKwCurrent = parseInt(opt.value);
		});
	};

	const onMaxChange = (name: string, opt: any) => {
		runInAction(() => {
			agtAkumaPlanerUiStore.maxKwCurrent = parseInt(opt.value);
		});
	};

	return (
		<>
			<div className="grid is-col-auto align-center justify-start pad-1rem">
				<strong>Zeitraum: </strong>
				<div>
					<SelectInput
						name="kwMin"
						emptyText="Start KW"
						value={agtAkumaPlanerUiStore.minKw}
						onChange={onMinChange}
						opts={agtAkumaPlanerUiStore.kwsOpts}
						isClearable={false}
					/>
				</div>
				<span>bis</span>
				<div>
					<SelectInput
						name="kwMax"
						emptyText="End KW"
						value={agtAkumaPlanerUiStore.maxKw}
						onChange={onMaxChange}
						opts={agtAkumaPlanerUiStore.kwsOpts}
						isClearable={false}
					/>
				</div>
			</div>
		</>
	);
});

export const AkumaPlanerListe = observer(() => {
	const { berichteUiiStore, agtAkumaPlanerUiStore: agtAkumePlanerUiStore, agenturListUiStore } = useStore();
	const history = useHistory();

	const formatGpWochen = (val: number) => {
		return `${val} ${val === 1 ? 'Woche' : 'Wochen'}`;
	};

	const tm = useMemo(() => {
		const tm = new TableModel<AgtAkumaPlanerVM, number>();
		const cols: ITableColumn<AgtAkumaPlanerVM, number>[] = [];

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
			render: AgenturNameCell,
		});

		AkumaPlanerArtSchema.forEach((art) => {
			cols.push({
				label: art.label,
				path: `${art.path}`,
				format: art.path === 'gpWochen' ? formatGpWochen : formatNum,
				agg: [
					{
						path: `${art.path}`,
						aggType: AggregrationType.sum,
					},
				],
			});
		});

		tm.onRowClick = (row: TableRow<AgtAkumaPlanerVM>) => {
			history.push({
				search: '?agtId=' + row.data.agt.agtId,
			});
		};

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, [history]);

	useEffect(() => {
		agtAkumePlanerUiStore.load().then(() => {
			let items = agtAkumePlanerUiStore.currentKWItems;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = agtAkumePlanerUiStore.filterByAgtIdandKw(agtId);
				items = _.sortBy(items, 'bm').reverse();
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtAkumaPlanerVM[];
			}

			tm.setRowData(items);
			tm.sort();
		});
	}, [
		tm,
		berichteUiiStore.selectedAgt,
		agenturListUiStore.filterHasChanged,
		agenturListUiStore,
		agtAkumePlanerUiStore.allVmItems,
		agtAkumePlanerUiStore.currentKWItems,
		agtAkumePlanerUiStore.maxKw,
		agtAkumePlanerUiStore.minKw,
		agtAkumePlanerUiStore,
	]);

	return (
		<>
			<div className="table-container pad-bottom-3rem">
				<AkumaPlanerOptions />
				<div className="table-container">
					<div className="pad-1rem">
						<AkumaPlanerSankeyChart />
					</div>
					<Table stickyHeader={true} tm={tm}></Table>
					<AkumaPlanerLegend />
				</div>
			</div>
		</>
	);
});
