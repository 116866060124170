import { AgenturStore } from '../agentur.store';
import { SessionStore } from '../session.store';
import { runInAction, observable, makeObservable, action } from 'mobx';
import { AgenturModel, AgtType } from 'app/models/agentur.model';
import { DigitalreportModel, KvAgtDigitalreportStore } from '../kv.agt.digitalreport.store';
import { BaseUiStore } from './base.ui.store';

export class DigitalReportVM {
	agt: AgenturModel;
	pos: DigitalreportModel;

	constructor(agt: AgenturModel, pos: DigitalreportModel) {
		this.agt = agt;
		this.pos = pos;
	}
}

export class AgtDigitalreportUiStore extends BaseUiStore<DigitalReportVM> {
	name = 'AgtDigitalreportUiStore';
	session: SessionStore;
	agenturStore: AgenturStore;
	kvAgtDigitalreportStore: KvAgtDigitalreportStore;
	constructor(session: SessionStore, agenturStore: AgenturStore, kvAgtDigitalreportStore: KvAgtDigitalreportStore) {
		super();
		makeObservable(this);
		this.session = session;
		this.agenturStore = agenturStore;
		this.kvAgtDigitalreportStore = kvAgtDigitalreportStore;
	}

	@action
	async _baseLoad() {
		const promises = await Promise.all([this.agenturStore.findAll(), this.kvAgtDigitalreportStore.load()])
		const agts = promises[0].filter((x) => x.agtType === AgtType.agt);
		const items = promises[1];
		const res: DigitalReportVM[] = [];
		runInAction(() => {
			items.forEach((i) => {
				const agt = agts.find((a) => a.agtId === i.agtId);
				if (agt) {
					res.push(new DigitalReportVM(agt!, i));
				}
			});
			this.items = res;
		});
	}
}
