import { BranchenplanungPosDef } from 'app/models/branchenplanung/bp.pos.def';
import { Steart } from 'app/models/core/steart.model';
import { UserModel } from 'app/models/core/user.model';
import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { BPMAXYEAR, BPMINYEAR } from './ui/berichte.ui.store';

export enum UmrechFaktor {
	bewertung = 'bewertung',
	brancheneinheit = 'brancheneinheit',
}

export const DefaultUmrechnungsFaktor = UmrechFaktor.bewertung;

export class AgtBpBaseModel {
	constructor(opts: any) {
		makeObservable(this);
		this.bnrId = opts.bnrId;
		this.agtId = opts.agtId;
		this.year = opts.year;
		this.baufi = opts.baufi;
		this.bauspar = opts.bauspar;
		this.bestandsZuwachs = opts.bestandsZuwachs;
		this.firmenSachOhneKraft = opts.firmenSachOhneKraft;
		this.fonds = opts.fonds;
		this.gesamtBewertung = opts.gesamtBewertung;
		this.kfzHaftPkw = opts.kfzHaftPkw;
		this.kranken = opts.kranken;
		this.leben = opts.leben;
		this.psachOhneKraftUnfall = opts.psachOhneKraftUnfall;
		this.reallyEditable = opts.reallyEditable;
		this.transferDate = opts.transferDate;
		this.transferId = opts.transferId;
		this.transferRefId = opts.transferRefId;
		this.transferText = opts.transferText;
		this.unfall = opts.unfall;
		this.user = new UserModel(opts.user || {});
	}

	@action
	add(d: AgtBpBaseModel) {
		BranchenplanungPosDef.forEach((pos) => {
			let v = _.get(this, pos.prop);
			if (!v) {
				v = 0;
			}
			const v1 = _.get(d, pos.prop);
			if (v1 > 0) {
				_.set(this, pos.prop, v1 + v);
			}
		});
	}

	bnrId: number;
	agtId: number;
	year: number;
	@observable baufi?: number;
	@observable bauspar?: number;
	@observable bestandsZuwachs?: number;
	@observable firmenSachOhneKraft?: number;
	@observable fonds?: number;
	@observable gesamtBewertung?: number;
	@observable kfzHaftPkw?: number;
	@observable kranken?: number;
	@observable leben?: number;
	@observable psachOhneKraftUnfall?: number;
	@observable unfall?: number;
	reallyEditable?: boolean;
	transferDate?: Date;
	transferId?: string;
	transferRefId?: string;
	transferText?: string;
	// user who created this record
	user: UserModel;
}

export class AgtBpGslModel extends AgtBpBaseModel {
	@observable baufi_steart?: Steart;
	@observable bauspar_steart?: Steart;
	@observable bestandsZuwachs_steart?: Steart;
	@observable firmenSachOhneKraft_steart?: Steart;
	@observable fonds_steart?: Steart;
	@observable gesamtBewertung_steart?: Steart;
	@observable kfzHaftPkw_steart?: Steart;
	@observable kranken_steart?: Steart;
	@observable leben_steart?: Steart;
	@observable psachOhneKraftUnfall_steart?: Steart;
	@observable unfall_steart?: Steart;
}

export class AgtBpStore extends BaseCachedEntityStore<AgtBpBaseModel> {
	apiPath = 'api/agt/bp/minyear/' + BPMINYEAR;
	idProp: string = 'id';
	createInstances(opts: any): AgtBpBaseModel {
		return new AgtBpBaseModel(opts);
	}

	disableFindForStearts = [123];

	@computed
	get years() {
		const years = [];
		for (let y = BPMAXYEAR; y >= BPMINYEAR; y--) {

			years.push(y);
		}
		return years;
	}
}
