import { makeObservable, observable } from 'mobx';

export class KvAgtMsLiteModel {
	constructor(opts: any) {
		this.bm = opts.bm;
		this.agtId = opts.aid;
		this.j = opts.j!;
	}

	agtId: number;
	bm: number;
	j: any;

}
