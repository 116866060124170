import Config from 'Config';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { SessionStore } from '../session.store';

const path = require('path-browserify');
export interface IResponse {
	message?: string;
	status?: number;
	data?: any;
}

export class BaseApiStore {
	apiPath: string = 'api';
	session: SessionStore;
	constructor(session: SessionStore) {
		makeObservable(this);
		this.session = session;
	}

	@computed
	get apiName() {
		return this.apiPath.toLowerCase().replace('/', '_').replace(' ', '');
	}

	@observable
	disableFindForStearts: number[] = [];

	@computed
	get disableFind() {
		if (!this.currentSteartId || this.disableFindForStearts.length === 0) {
			return false;
		}
		if (this.disableFindForStearts.includes(this.currentSteartId)) {
			return true;
		}
		return false;
	}

	@computed
	get currentSteartId() {
		if (this.session && this.session.currentUser) {
			return this.session.currentUser.steart.id;
		}
		return null;
	}

	@computed
	get axios() {
		return this.session.axios;
	}

	buildPublicUrl(p: string) {
		var serverUrl = Config.API_BASE_URL;
		if (!p.startsWith('/')) {
			p = '/' + p;
		}
		var url = serverUrl + p;
		return url;
	}

	@computed
	get secondsSinceLastApiCall() {
		return (new Date().getTime() - this.lastApiCall) / 1000;
	}

	lastApiCall = new Date().getTime();

	getAuthHeader() {
		const token = localStorage.getItem('token');
		const headers: any = {
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: 'Basic ' + window.btoa(Config.basicAuth),
			'x-auth-token': token,
		};
		this.lastApiCall = new Date().getTime();
		return {
			headers,
		};
	}

	@action
	async callRefreshTokenIfNecessary() {
		if (!this.session.refreshToken) {
			return false;
		}
		if (!this.session.lastTokenUpdate) {
			return false;
		}
		var lastMom = moment(this.session.lastTokenUpdate);
		var mom = moment();
		var duration = moment.duration(mom.diff(lastMom));
		var seconds = duration.asSeconds();
		if (seconds < 180) {
			return false;
		}
		await this.callRefreshToken();
	}

	@action
	async callRefreshToken() {
		const data = JSON.stringify({
			refreshToken: this.session.refreshToken,
		});
		const headers = {
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: 'Basic ' + window.btoa(Config.basicAuth),
		};
		const res = await this.axios.post('/api/auth/refresh', data, { headers }).catch(this._handleError);
		if (res && res.data && res.data.token) {
			localStorage.setItem('token', res.data.token);
			this.session.token = res.data.token;
			this.session.lastTokenUpdate = new Date();
		} else {
			debugger;
		}
	}

	@action
	async post(relPath: string, data: any): Promise<IResponse> {
		await this.session.incApiCallCount();
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		let url = this.apiPath;
		if (relPath) {
			url = path.join(this.apiPath, relPath);
		}
		return this.axios.post(url, data, header).catch(this._handleError).finally(this.session.decApiCallCount.bind(this.session));
	}

	@action
	async put(relPath: string, data: any): Promise<IResponse> {
		await this.session.incApiCallCount();
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		if (typeof relPath == 'number') {
			// @ts-ignore
			relPath = relPath.toString();
		}
		const url = path.join(this.apiPath, relPath);
		return this.axios.put(url, data, header).catch(this._handleError).finally(this.session.decApiCallCount.bind(this.session));
	}

	@action
	async get(urlpath: string, relativePath: boolean = true): Promise<IResponse> {
		await this.session.incApiCallCount();
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		let url: string;
		if (relativePath) {
			url = path.join(this.apiPath, urlpath);
		} else {
			url = urlpath;
		}
		return this.axios.get(url, { headers: header.headers }).catch(this._handleError).finally(this.session.decApiCallCount.bind(this.session));
	}

	// @action
	// async getFile(urlpath: string, relativePath: boolean = true): Promise<IResponse> {
	// 	this.session.incApiCallCount();
	// 	await this.callRefreshTokenIfNecessary();
	// 	const header = this.getAuthHeader();
	// 	let url: string;
	// 	url = path.join(this.apiPath, urlpath);
	// 	return this.axios.get(url, header).catch(this._handleError).finally(this.session.decApiCallCount.bind(this.session));
	// }

	@action
	async delete(urlpath: string, relativePath: boolean = true): Promise<IResponse> {
		await this.session.incApiCallCount();
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		let url: string;
		if (relativePath) {
			url = path.join(this.apiPath, urlpath);
		} else {
			url = urlpath;
		}
		return this.axios.delete(url, header).catch(this._handleError).finally(this.session.decApiCallCount.bind(this.session));
	}

	_handleError(error: any): IResponse {
		let res: IResponse = {};
		if (error.response) {
			if (error.response.data) {
				const data = error.response.data;
				if (typeof data === 'string') {
					res.message = data;
				} else {
					res = error.response.data;
					debugger;
					// // delete res.statusCode;
					// res.status = data.status;
					// if (data.statusCode && data.statusCode === 422) {
					// 	res.message = 'api:' + res.message;
					// }
				}
			}
			if (error.response.status) {
				res.status = error.response.status;
			}
			if (error.response.statusText && !res.message) {
				res.message = error.response.statusText;
			}
			return res;
		} else if (error.request) {
			debugger;
			// The request was made but no response was received
			console.log(error.request);
			return {
				status: error.request.status,
			};
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			debugger;
			return error.message;
		}
	}
}
