import { TableModel, ITableColumn } from 'app/components/table/table.model';
import { Table } from 'app/components/table/table';
import { useStore } from 'app/context';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { useMemo, useEffect, useState } from 'react';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { LeadNowIcon } from './LeadNowIcon';
import { LeadNowOverallState } from './LeadNowOverallState';
import { useHistory } from 'react-router';
import routes from 'routes';
import { LeadNowEdit } from './LeadnowEdit';
import { LeadNowCheckVM } from 'app/models/leadnowcheck/leadnow.check.vm';
import { notify } from 'app/components/common/notify';

export const schwerpunktSchema = Yup.object().shape({
	schwerpunktBBS: Yup.boolean(),
	schwerpunktKME: Yup.boolean(),
	schwerpunktKVS: Yup.boolean(),
	schwerpunktUB: Yup.boolean(),
	schwerpunktVAS: Yup.boolean(),
});

export const formatBool = (val: any) => {
	return val === true ? 'x' : '';
};

export const LeadnowList = observer(() => {
	const { agtLeadNowCheckUiStore, anwendungenUiStore, agenturUiStore, agenturListUiStore } = useStore();
	const history = useHistory();
	const [loaded, setLoadad] = useState<boolean>(false);

	const tm = useMemo(() => {
		const tm = new TableModel<LeadNowCheckVM, number>();

		const cols: ITableColumn<LeadNowCheckVM, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: ['Lead.NOW', 'Empfehlung'],
				path: 'score',
				sortBy: 'score',
				render: (row) => {
					return <LeadNowIcon iconType={row.data.iconType} iconColor={row.data.iconColor} />;
				},
			},
			{
				label: 'Status',
				path: 'overallState',
				sortBy: 'overallState',
				render: (row) => {
					return <LeadNowOverallState model={row.data} />;
				},
			},
			// {
			// 	label: 'empfehlung',
			// 	path: 'empfehlung',
			// 	sortBy: 'empfehlung',
			// },
		];

		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		tm.onRowClick = (row) => {
			const p = routes.AGENTUR.getPath(row.data.agtId.toString(), 'anwendungen', 'checklisten', 'leadnow');
			history.push(p);
		};
		return tm;
	}, [history]);

	useEffect(() => {
		agtLeadNowCheckUiStore.load().then(() => {
			let res = agtLeadNowCheckUiStore.items;
			res = agenturListUiStore.applyFilterOnAgt(res) as LeadNowCheckVM[];
			if (anwendungenUiStore.selectedAgt) {
				const agtId = anwendungenUiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
			setLoadad(true);
		});
	}, [tm, setLoadad, agtLeadNowCheckUiStore, anwendungenUiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	const onEditDone = () => {
		if (agenturUiStore.current) {
			notify('Änderungen wurden gespeichert', '', 'info');
		} else {
			const p = routes.ANWENDUNGEN.getPath('checklisten', 'leadnow');
			history.push(p);
		}
	};

	const getTableLegend = (): JSX.Element => {
		return (
			<div className="leadnow-legend">
				<div className="legend-item">
					<LeadNowIcon iconType={'outline'} iconColor={LeadNowCheckVM.ColorGood} />
					<span>Lead.NOW Empfehlung, jedoch unbearbeitet.</span>
				</div>
				<div className="legend-item">
					<LeadNowIcon iconType={'rejected'} iconColor={LeadNowCheckVM.ColorBad} />
					<span>Agentur hat Teilnahme abgelehnt.</span>
				</div>
				<div className="legend-item">
					<LeadNowIcon iconType={'full'} iconColor={LeadNowCheckVM.ColorGood} />
					<span>Agentur wird teilnehmen oder nimmt bereits teil.</span>
				</div>
			</div>
		);
	};

	return (
		<>
			{anwendungenUiStore.selectedAgt && (
				<>
					<LeadNowEdit agt={anwendungenUiStore.selectedAgt} onDone={onEditDone} />
				</>
			)}
			{!anwendungenUiStore.selectedAgt && loaded && (
				<>
					<Table tm={tm} schema={schwerpunktSchema} legend={getTableLegend()} />
				</>
			)}
		</>
	);
});
