import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { formatNum, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { ScatterPlotChart, ScatterPlotModel, ScatterPlotVarationModel } from 'app/components/charts/ScatterPlotChart';
import { Tooltip, TooltipProps } from 'recharts';
import { AgtBestandVerlauf } from './AgtBestandVerlauf';
import { useHistory } from 'react-router-dom';
import { AgtBestandUndBewertungVM } from 'app/models/berichte/agt.bestand.und.bewertung.vm';
import { AgtBewertungsVerlauf } from './AgtBewrtungsverlauf';
import { AgtBewertungsverlaufMonatsDelta } from './AgtBewertungsverlaufMonatsDelta';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Icon } from 'app/components/common/Icon';
import colors from 'assets/scss/colors.module.scss';

const BundBInfoBox = () => {
	return (
		<div className="note-box content">
			<p>
				<Icon iconClass="info-circle" />
			</p>
			<div className="note-message">
				<p>
					Bestandszuwachs: Zuwachs zum Ausgangsbestand ohne Überweisungen an andere Betriebsstätte und ohne Saldo aus Bestandsübertragungen. <br />
				</p>
				<p>
					Berechnung Bestandszuwachs: aktueller Bestand - Bestand zum 31.12.VJ - Bestandsübertragungen + Storno Überweis oder: langfristiges Neugeschäft - Storno
					o.Überweis. + generelle Bewegungen + individuelle Bewegungen + beitragsfreie Bewegungen (nur UBR Verträge)
				</p>
			</div>
		</div>
	);
};

export const BestandUndBewertungsliste = observer(() => {
	const { berichteUiiStore, agtBestandUiStore: bestandUiStore, widgetUiStore, agenturListUiStore } = useStore();
	const history = useHistory();

	const formatPerc = (val: number) => {
		return `${formatPercent(val, 2)} %`;
	};

	const tm = useMemo(() => {
		const tm = new TableModel<AgtBestandUndBewertungVM, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: ['Bestand Sach Gesamt ', 'VJ Bestand Sach Gesamt '],
				path: ['bestand.val.bt', 'bestandVJ.val.bt'],
				sortBy: 'bestand.val.bt',
				format: formatNum,
			},
			{
				label: ['Bestandszuwachs Sach *', 'Bestandszuwachs Sach (%)'],
				path: ['bestand.zuwachs.bt', 'bestand.deltaBt'],
				sortBy: 'bestandsZuwachsSach.bt',
				format: [formatNum, formatPerc],
			},
			{
				label: ['Bewertung', 'VJ Bewertung'],
				path: ['bewertung.val.bt', 'bewertungVJ.val.bt'],
				sortBy: 'bewertung.bt',
				format: formatNum,
			},
			{
				label: ['Bewertungs-', 'veränderung (%)'],
				path: ['bewertungsveraenderung'],
				format: formatPerc,
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'agt.name';
		tm.idProperty = 'agtId';
		tm.idType = 'number';

		tm.onRowClick = (row: TableRow<AgtBestandUndBewertungVM>) => {
			history.push({
				search: '?agtId=' + row.data.agtId,
			});
		};

		return tm;
	}, [history]);

	useEffect(() => {
		bestandUiStore.load().then(() => {
			let items = bestandUiStore.items;
			agenturListUiStore.applyFilterOnAgt(items) as AgtBestandUndBewertungVM[];
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(items);
		});
	}, [tm, bestandUiStore, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	const fillColor = (ze: number) => {
		if (!ze) {
			return colors.greyLight;
		}

		if (ze < 0) {
			return colors.error;
		}
		if (ze < 10) {
			return colors.warning;
		}

		return colors.success;
	};

	const chartOpts = new ScatterPlotModel({
		tableModel: tm,
		variations: [
			new ScatterPlotVarationModel({
				key: 'bestandgesamt',
				buttonLabel: 'Bestand Gesamt',
				pathX: 'bestand.deltaBtLimited',
				labelX: 'Bestandszuwachs %',
				pathY: 'bestand.val.bt',
				labelY: 'Bestand Sach Gesamt',
				plotColor: fillColor,
				domainX: [-10, 10],
			}),
			new ScatterPlotVarationModel({
				key: 'bestanddelta',
				buttonLabel: 'Bestand Delta VJ',
				pathX: 'bestand.deltaBtLimited',
				labelX: 'Bestandszuwachs %',
				pathY: 'bestand.zuwachs.bt',
				labelY: 'Delta Bestand Sach Gesamt',
				plotColor: fillColor,
				domainX: [-10, 10],
			}),
			new ScatterPlotVarationModel({
				key: 'bewertunggesamt',
				buttonLabel: 'Bewertung Gesamt',
				pathX: 'bewertungsveraenderungLimited',
				labelX: 'Bewertungsveränderung %',
				pathY: 'bewertung.val.bt',
				labelY: 'Bewertung',
				plotColor: fillColor,
				domainX: [-100, 100],
			}),
			new ScatterPlotVarationModel({
				key: 'bewertungdelta',
				buttonLabel: 'Bewertung Delta VJ',
				pathX: 'bewertungsveraenderungLimited',
				labelX: 'Bewertungsveränderung %',
				pathY: 'bewertungDeltaVJ',
				labelY: 'Delta: Bewertung',
				plotColor: fillColor,
				domainX: [-100, 100],
			}),
		],
	});

	return (
		<>
			<div className="table-container">
				<div className="pad-1rem">
					<div className="grid">
						{berichteUiiStore.selectedAgt ? (
							<>
								<BundBInfoBox />
								<AgtBestandVerlauf />
								<AgtBewertungsVerlauf />
								<AgtBewertungsverlaufMonatsDelta />
							</>
						) : (
							<>
								<ScatterPlotChart {...chartOpts} tooltip={<Tooltip content={<AgtPosTooltip />} />} />
								{widgetUiStore.bestandsZuwachsWidget && <>{widgetUiStore.bestandsZuwachsWidget.component}</>}
								<BundBInfoBox />
							</>
						)}
					</div>
				</div>
				<Table tm={tm} stickyHeader={true}></Table>
			</div>
		</>
	);
});

const AgtPosTooltip = (props: TooltipProps<any, any>) => {
	const { active, payload } = props;
	if (active && payload && payload.length) {
		const data = payload[0].payload as AgtBestandUndBewertungVM;
		return (
			<div className="custom-tooltip">
				<p className="label">{`${data.agt.name} (${data.agt.agtId})`}</p>
				{data.bestand && (
					<>
						<p className="label">Ergebnis: {data.bestand.val.bt}</p>
						{/* <p className="label">Ziel: {data.posVal.ziel}</p>
						<p className="label">Zielerreichung: {data.posVal.ze}</p>
						<p className="label">ZE*: {data.posVal.zeStern}</p>
						<p className="label">Zeit ZE: {data.posVal.zeZeit}</p> */}
					</>
				)}
			</div>
		);
	}

	return null;
};
