import { TaskUiStore } from './task.ui.store';
import { DocumentUiStore } from './document.ui.store';
import { BnrErrungenschaftenUiStore } from './bnr.errungenschaften.ui.store';
import { AgtErrungenschaftenUiStore } from './agt.errungenschaften.ui.store';
import { PersonalUiStore } from './personal.ui.store';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { SessionStore } from '../session.store';
import { AgenturStore } from '../agentur.store';
import { AgtExpertisenModel } from 'app/models/agentur.model';
import { NewsUiStore } from './news.ui.store';
import { BaseUiStore } from './base.ui.store';

class WochenmailTaskStats {
	constructor() {
		makeObservable(this);
	}
	@observable
	total: number = 0;
	@observable
	offen: number = 0;
	@observable
	erledigt: number = 0;
	@observable
	dueThisWeek: number = 0;
}
class WochenmailSharedDocs {
	constructor() {
		makeObservable(this);
	}
	@observable
	total: number = 0;

	@observable
	unreadTotal: number = 0;

	@observable
	newThisWeek: number = 0;
}

export class WochenmailChangeSummary {
	constructor() {
		makeObservable(this);
	}

	@observable
	steart: string = '';

	@observable
	agt: number = 0;

	@observable
	bnr: number = 0;

	@observable
	bds: number = 0;

	@observable
	jbls: number = 0;

	@observable
	tasksDue: number = 0;

	@observable
	newShrdDcs: number = 0;

	@observable
	endingExprts: number = 0;

	@observable
	newsItems: number = 0;

	@computed
	get isAnyPropertyGreaterZero() {
		return this.agt > 0 || this.bnr > 0 || this.bds > 0 || this.jbls > 0 || this.tasksDue > 0 || this.newShrdDcs > 0 || this.endingExprts > 0 || this.newsItems > 0;
	}
}

export class WochenmailtUiStore extends BaseUiStore<any> {
	name = 'WochenmailtUiStore';
	session: SessionStore;
	taskUiStore: TaskUiStore;
	documentUiStore: DocumentUiStore;
	bnrErrungenschaftenUiStore: BnrErrungenschaftenUiStore;
	agtErrungenschaftenUiStore: AgtErrungenschaftenUiStore;
	personalUiStore: PersonalUiStore;
	agenturStore: AgenturStore;
	newsUiStore: NewsUiStore
	constructor(
		session: SessionStore,
		taskUiStore: TaskUiStore,
		documentUiStore: DocumentUiStore,
		bnrErrungenschaftenUiStore: BnrErrungenschaftenUiStore,
		agtErrungenschaftenUiStore: AgtErrungenschaftenUiStore,
		personalUiStore: PersonalUiStore,
		agenturStore: AgenturStore,
		newsUiStore: NewsUiStore
	) {
		super();
		makeObservable(this);
		this.session = session;
		this.taskUiStore = taskUiStore;
		this.documentUiStore = documentUiStore;
		this.bnrErrungenschaftenUiStore = bnrErrungenschaftenUiStore;
		this.agtErrungenschaftenUiStore = agtErrungenschaftenUiStore;
		this.personalUiStore = personalUiStore;
		this.agenturStore = agenturStore;
		this.newsUiStore = newsUiStore
		if (localStorage.getItem('wochenMailShowAllSectios') === 'true') {
			this.showAllSections = true;
		}
	}

	@observable
	loaded: boolean = false;

	@observable
	showAllSections = false;

	@action
	toggleShowAllSections() {
		this.showAllSections = !this.showAllSections;
		localStorage.setItem('wochenMailShowAllSectios', this.showAllSections.toString());
	}

	async _baseLoad() {
		this.loaded = false;
		this.agtErrungenschaftenUiStore.clearCache();

		/* prettier-ignore */
		await Promise.all([
			this.loadTasks(),
			this.loadSharedDocs(),
			this.loadPersonal(),
			this.bnrErrungenschaftenUiStore.load(),
			this.agtErrungenschaftenUiStore.load(),
			this.newsUiStore.load()
		]);
	}

	@computed
	get last3ItemsWochenmail() {

		const lastCrawlDate = this.agenturStore.session.currentUser!.lastCrawlDate;
		let items = this.newsUiStore.last3Items;

		if (lastCrawlDate) {
			items = items.filter(n => n.isPublishedBefore(lastCrawlDate));
		}


		if (!items) {
			return [];
		}
		if (items.length < 3) {
			return items;
		}
		return items.slice(0, 3);
	}

	@computed
	get isVblOrLeiter() {
		if (this.session.currentUser!.isLeiter || this.session.currentUser!.isVbl) {
			return true;
		}
		return false;
	}

	@computed
	get changesThisWeekSummary() {
		const x = new WochenmailChangeSummary();
		runInAction(() => {
			x.steart = this.session.currentSteart!.name;
			x.agt = this.changedAgtErrungenschaften.length;
			x.bnr = this.changedBnrErrungenschaften.length;
			x.tasksDue = this.taskStats.dueThisWeek;
			x.newShrdDcs = this.sharedDocs.newThisWeek;
			if (this.isVblOrLeiter) {
				x.endingExprts = this.agtWithEndingExpertisen.length;
				x.jbls = this.jubels.length;
				x.bds = this.birthdays.length;
			}
			x.newsItems = this.last3ItemsWochenmail.length
		});
		return x;
	}

	@computed
	get hasChangesThisWeek() {
		return this.changesThisWeekSummary.isAnyPropertyGreaterZero;
	}

	@computed
	get endingExpertisen() {
		const agts = this.agenturStore.agenturen;
		const res: AgtExpertisenModel[] = [];
		agts.forEach((a) => {
			a.allExpertisen.forEach((e) => {
				// console.log(a.agtId, e.text, e.ablaufInDays);
				if (e.displayInWochenmail) {
					res.push(e);
				}
			});
		});
		return res;
	}
	@computed
	get agtWithEndingExpertisen() {
		return this.agenturStore.agenturen.filter((a) => a.wochenMailEndingExpertisen.length > 0);
	}

	@computed
	get showExpertisen() {
		return (this.isVblOrLeiter && this.endingExpertisen.length > 0) || this.showAllSections;
	}

	@computed
	get changedBnrErrungenschaften() {
		return this.bnrErrungenschaftenUiStore.changedThisKw;
	}

	@computed
	get showBnrErrugenshaften() {
		return this.changedBnrErrungenschaften.length > 0 || this.showAllSections;
	}

	@computed
	get agtErrungenschaftenSummaryItems() {
		return this.agtErrungenschaftenUiStore.agtErrungenschaftenSummaryItems;
	}

	@computed
	get changedAgtErrungenschaften() {
		return this.agtErrungenschaftenSummaryItems.filter(x => x.countCurrentKw > 0);
	}

	@computed
	get showAgtErrugenshaften() {

		const x = this.changedAgtErrungenschaften.filter(x => x.countCurrentKw > 0);
		if (x && x.length > 0) return true;
		if (this.showAllSections) return true;
		return false;
	}

	@computed
	get birthdays() {
		let items = this.personalUiStore.items;
		items = items.filter((p) => p.hasUpcomingBirthday14 && p.canShowGeburtstag);
		items = _.sortBy(items, 'nextBirthdayInDaysForCalc');
		return items;
	}

	@computed
	get showBirthdays() {
		return this.birthdays.length > 0 || this.showAllSections;
	}

	@computed
	get jubels() {
		let items = this.personalUiStore.items;
		items = items.filter((p) => p.hasUpcomingAnniversary);
		items = items.filter((p) => p.workingYears > 1 && p.workingYears < 99);
		items = items.filter((p) => p.hasAnniversary);
		return items;
	}

	@computed
	get showJubels() {
		return this.jubels.length > 0 || this.showAllSections;
	}

	async loadPersonal() {
		await this.personalUiStore.load();
	}

	@computed
	get showSharedDocs() {
		return this.sharedDocs.newThisWeek > 0 || this.showAllSections;
	}

	sharedDocs: WochenmailSharedDocs = new WochenmailSharedDocs();
	async loadSharedDocs() {
		await this.documentUiStore.load();
		this.sharedDocs.total = this.documentUiStore.sharedCountCurrentYear;
		this.sharedDocs.unreadTotal = this.documentUiStore.sharedCountCurrentYearUnread;
		this.sharedDocs.newThisWeek = this.documentUiStore.sharedChangedOrNewSinceLastWeek;
	}

	@computed
	get showTasks() {
		return this.taskStats.dueThisWeek > 0 || this.showAllSections;
	}

	taskStats: WochenmailTaskStats = new WochenmailTaskStats();
	async loadTasks() {
		await this.taskUiStore.load();
		this.taskStats.total = this.taskUiStore.stats.total;
		this.taskStats.offen = this.taskUiStore.stats.offen;
		this.taskStats.erledigt = this.taskUiStore.stats.erledigt;
		this.taskStats.dueThisWeek = this.taskUiStore.stats.dueThisWeek;
	}

	@computed
	get showNews() {
		// return true;;
		return this.last3ItemsWochenmail.length > 0;
	}

}
