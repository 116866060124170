import { UserModel } from 'app/models/core/user.model';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { UserStore } from '../user.store';
import { BaseUiStore } from './base.ui.store';

export class AgtUserUiStore extends BaseUiStore<UserModel> {
	name = 'AgtUserUiStore';
	userStore: UserStore;

	constructor(userStore: UserStore) {
		super();
		makeObservable(this);
		this.userStore = userStore;
	}
	@action
	async _baseLoad() {
		const res = await this.userStore.findAll();
		runInAction(() => {
			this.items = res;
		});
	}

	async getUserByBnrId(bnrId: number) {
		await this.load();
		return this.items.find((u) => u.bnrId === bnrId);
	}

	@computed
	get betreuerOptsExcludingSelf() {
		let sorted = _.sortBy(this.items, 'steartText', 'name').reverse();
		let betreuer = sorted.map((u) => {
			return {
				label: `${u.name} [${u.steartText}] `,
				value: u.bnrId,
			};
		});
		betreuer = [
			{
				label: `Ohne VB `,
				value: 999,
			},
		].concat(betreuer.filter((b) => b.value !== this.userStore.session.currentUser!.bnrId));
		return betreuer;
	}

	@computed
	get betreuerOptsAllExcludingSelf() {
		let sorted = _.sortBy(this.items, 'steartText', 'name').reverse();
		sorted.filter((u) => u.steartText !== 'GSL'); // remove GSL

		let betreuer = sorted.map((u) => {
			return {
				label: `${u.name} [${u.steartText}] `,
				value: u.bnrId,
			};
		});
		return betreuer.filter((b) => b.value !== this.userStore.session.currentUser!.bnrId);
	}
	onLogout() {
		this.userStore.onLogout();
	}
}
