import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import React, { useEffect, useMemo, useState } from 'react';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { Table } from 'app/components/table/table';
import { formatDate } from 'app/utils';
import { PersonalVM } from 'app/models/personal/personal.vm';
import { WidgetNavigate } from '../common/WidgetNavigate';

interface IGeburtstageTable {
	fourteenDaysFilter: boolean;
}

export const GeburtstageTable = observer((props: IGeburtstageTable) => {
	const [upComeCount, setUpComeCount] = useState(0);

	const { agenturUiStore, personalUiStore } = useStore();
	const agt = agenturUiStore.current;

	const tm = useMemo(() => {
		const tm = new TableModel<PersonalVM, number>();
		const cols = [
			{
				label: 'Geburtsdatum',
				path: 'gebDat',
				sortBy: 'gebDatMonthDay',
				format: formatDate,
			},
			// {
			// 	label: 'Geburtsdatum',
			// 	path: 'nextBirthdayInDaysForCalc',
			// 	sortBy: 'nextBirthdayInDaysForCalc',
			// },
			{
				label: 'Name',
				path: 'nachname',
				render: (row: TableRow<PersonalVM>) => {
					return (
						<>
							{row.data.fullName} ({row.data.stellenBezeichnung})
						</>
					);
				},
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'nextBirthdayInDaysForCalc';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);
	useEffect(() => {
		personalUiStore.load().then(() => {
			let res = personalUiStore.items;
			res = res.filter((p) => (props.fourteenDaysFilter ? p.hasUpcomingBirthday14 : p.hasUpcomingBirthday));
			if (agt) {
				res = res.filter((p) => p.agtId === agt.agtId);
			}
			tm.setRowData(res);
			setUpComeCount(res.length);
		});
	}, [tm, personalUiStore, agt, setUpComeCount, upComeCount, props.fourteenDaysFilter]);

	return <>{upComeCount > 0 ? <Table tm={tm}></Table> : <div className="pad-1rem">Keine anstehenden Geburtstage</div>}</>;
});

export const GeburtstageWidget = observer((props: IWidgetConsumer) => {
	const [upComeCount, setUpComeCount] = useState(0);

	const { agenturUiStore, personalUiStore } = useStore();
	const agt = agenturUiStore.current;

	useEffect(() => {
		personalUiStore.load().then(() => {
			let res = personalUiStore.items;
			res = res.filter((p) => p.hasUpcomingBirthday && p.canShowGeburtstag);
			setUpComeCount(res.length);
		});
	}, [personalUiStore, agt, setUpComeCount, upComeCount]);

	return (
		<Widget title={'Anstehende Geburtstage'} modifier={upComeCount > 0 ? 'is-paddingless' : ''} action={<WidgetNavigate text={'Alle'} path={'/berichte/personal/geburtstag'} />} widgetId={props.widgetKey}>
			{upComeCount && <GeburtstageTable fourteenDaysFilter={true} />}
		</Widget>
	);
});
