import { action, computed, makeObservable, observable } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { AgtGoogleProfileUiStore, GoogleProfileVM } from './agt.googleprofile.ui.store';
import { AgenturModel } from 'app/models/agentur.model';
import { AgtAkumaPrimeKwVM, AgtAkumaPrimeUiStore } from './agt.akumaprime.ui.store';
import { AgtAkumaPlanerUiStore, AgtAkumaPlanerVM } from './agt.akumaplaner.ui.store';
import { AgtDigitalreportUiStore, DigitalReportVM } from './agt.digitalreport.ui.store';
import { BaseUiStore } from './base.ui.store';

interface IFitModel {
    ziel?: number;
    ergebnis?: number;
    max?: number;
}

export class FitModel {
    constructor(opts: IFitModel) {
        makeObservable(this);
        this.ergebnis = opts.ergebnis;
        this.ziel = opts.ziel;
        this.max = opts.max;
    }

    ergebnis?: number;
    ziel?: number;
    max?: number;

    @computed
    get ze() {
        if (!this.ziel || !this.ergebnis) {
            return 0;
        }
        const z = this.ziel;
        if (z === 0) {
            return 0;
        }
        const e = this.ergebnis;
        let ze = (e / z) * 100;
        ze = Math.round(ze * 100) / 100;
        return ze;
    }
}

export class AgtFitnessVm {
    agt: AgenturModel;
    gup?: GoogleProfileVM;
    prime?: AgtAkumaPrimeKwVM;
    planer?: AgtAkumaPlanerVM;
    digiReport?: DigitalReportVM;
    isPrime?: boolean;

    constructor(agt: AgenturModel, gup?: GoogleProfileVM, prime?: AgtAkumaPrimeKwVM, planer?: AgtAkumaPlanerVM, digiReport?: DigitalReportVM, isPrime?: boolean) {
        this.agt = agt;
        this.gup = gup;
        this.prime = prime;
        this.planer = planer;
        this.digiReport = digiReport;
        this.isPrime = isPrime;
    }

    //// GOOGLE ///////
    @computed
    get anzBew() {
        if (!this.gup) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 25,
            ergebnis: this.gup.bew,
            max: 100,
        });
    }

    @computed
    get bewAvg() {
        if (!this.gup) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 5,
            ergebnis: this.gup.bewAvg,
        });
    }

    @computed
    get imgCount() {
        if (!this.gup) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 5,
            ergebnis: this.gup.imgCount,
        });
    }

    @computed
    get qCount() {
        if (!this.gup) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 5,
            ergebnis: this.gup.qCount,
        });
    }

    //// AMIS.NOW ???  ///////

    @computed
    get isAkumaPrimeUser() {
        if (this.prime && this.prime.einordnungsQuote > 0) {
            return 1;
        }
        return 0;
    }

    @computed
    get planerAnzahlWochenNutzung() {
        if (!this.planer) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 40,
            ergebnis: this.planer.gpWochen,
        });
    }

    @computed
    get planerUebertragsungquoteAmisNow() {
        // ?AmisNow?
        if (!this.planer) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 40,
            ergebnis: this.planer.uebertragungsQuote,
        });
    }

    @computed
    get primeEinordnungsquote() {
        if (!this.prime) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 100,
            ergebnis: this.prime.einordnungsQuote,
        });
    }

    @computed
    get primeAbwahlquote() {
        if (!this.prime) {
            return new FitModel({});
        }
        return new FitModel({
            ziel: 100,
            ergebnis: this.prime.abwahlQuote,
        });
    }

    @computed
    get primeTerminierungExtern() {
        if (!this.prime) {
            return new FitModel({});
        }
        return new FitModel({
            ergebnis: this.prime.terminierungExternQuote,
        });
    }


    /// Meine Allianz
    @computed
    get mazBestandDurchdringungsquote() {
        if (!this.digiReport) {
            return new FitModel({ ziel: -1 });
        }

        const anz = this.digiReport.pos.anzKunden;
        const quot = this.digiReport.pos.prozMaz;
        let erg = Math.round(anz * (quot / 100));

        erg = Math.round(erg);
        return new FitModel({
            ziel: anz,
            ergebnis: erg,
        });
    }

    @computed
    get mazNeuDurchdringungsquote() {
        if (!this.digiReport) {
            return new FitModel({});
        }
        const anz = this.digiReport.pos.anzKundenNeu;
        const quot = this.digiReport.pos.prozMazNeu;
        let erg = Math.round(anz * (quot / 100));

        return new FitModel({
            ziel: anz,
            ergebnis: erg,
        });
    }
}

export class AgtFitnessUiStore extends BaseUiStore<AgtFitnessVm> {
    name = 'AgtFitnessUiStore#';
    agtDigitalreportUiStore: AgtDigitalreportUiStore;
    agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore;
    agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore;
    agtGoolgeProfileUiStore: AgtGoogleProfileUiStore;
    agenturStore: AgenturStore;

    constructor(
        agenturStore: AgenturStore,
        agtGoolgeProfileUiStore: AgtGoogleProfileUiStore,
        agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore,
        agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore,
        agtDigitalreportUiStore: AgtDigitalreportUiStore,
    ) {
        super();
        this.agtDigitalreportUiStore = agtDigitalreportUiStore;
        this.agtAkumaPlanerUiStore = agtAkumaPlanerUiStore;
        this.agtAkumaPrimeUiStore = agtAkumaPrimeUiStore;
        this.agtGoolgeProfileUiStore = agtGoolgeProfileUiStore;
        this.agenturStore = agenturStore;
    }

    @action
    async _baseLoad() {
        const promises = await Promise.all([
            this.agenturStore.findAll(),
            this.agtGoolgeProfileUiStore.load(),
            this.agtAkumaPrimeUiStore.load(),
            this.agtAkumaPlanerUiStore.load(),
            this.agtDigitalreportUiStore.load(),
        ]);
        // const promises = await Promise.all([this.agenturStore.findAll(), this.agtGoolgeProfileUiStore.load(), this.agtDigitalreportUiStore.findAll()]);

        // set the the date ranges:
        this.agtGoolgeProfileUiStore.bmCurrent = this.agtGoolgeProfileUiStore.maxBm;
        this.agtAkumaPrimeUiStore.minKwCurrent = this.agtAkumaPrimeUiStore.minKw;
        this.agtAkumaPrimeUiStore.maxKwCurrent = this.agtAkumaPrimeUiStore.lastWeek;
        this.agtAkumaPlanerUiStore.minKwCurrent = this.agtAkumaPlanerUiStore.minKw;
        this.agtAkumaPlanerUiStore.maxKwCurrent = this.agtAkumaPlanerUiStore.lastWeek;


        let gups = this.agtGoolgeProfileUiStore.currentBMItems.slice();
        let primes = this.agtAkumaPrimeUiStore.currentKWItems.slice();
        let plans = this.agtAkumaPlanerUiStore.currentKWItems.slice();
        let digis = this.agtDigitalreportUiStore.items.slice();

        const res: AgtFitnessVm[] = [];
        const agts = promises[0];
        for (const agt of agts) {
            if (agt.gpShort === 'AGP') {
                continue;
            }
            if (!agt.vdId || !agt.gsId) {
                continue;
            }
            let gup;
            let prime = undefined;
            let planer = undefined;
            let digi = undefined;

            const isPrime = this.agtAkumaPrimeUiStore.checkAgtParticipation(agt.agtId);

            [gup, gups] = this.findAndSplice(gups, (a: any) => a.agt.agtId === agt.agtId);

            [prime, primes] = this.findAndSplice(primes, (a: any) => a.agt.agtId === agt.agtId);

            [planer, plans] = this.findAndSplice(plans, (a: any) => a.agt.agtId === agt.agtId);

            [digi, digis] = this.findAndSplice(digis, (a: any) => a.agt.agtId === agt.agtId);

            const vm = new AgtFitnessVm(agt, gup, prime, planer, digi, isPrime);

            res.push(vm);
        }

        this.items = res;
    }
}
