import { computed, makeObservable, observable, runInAction } from "mobx";
import { KvAgtGoogleProfileStore } from "../kv.agt.googleprofile.store";
import { AgenturStore } from "../agentur.store";
import { ISelectOpt } from "app/components/common/Select";
import { formatBM } from "app/utils";
import { AgenturModel } from "app/models/agentur.model";
import _ from "lodash";
import { BaseUiStore } from "./base.ui.store";
import { KvAgtMsLiteModel } from "app/models/kv.agt.ms.lite.model";


export class GoogleProfileBm {
    bm: number;
    bew: number;
    bewAvg: number;
    imgCount: number;
    qCount: number;
    constructor(bm: number, bew: number, bewAvg: number, imgCount: number, qCount: number) {

        this.bm = bm;
        this.bew = bew;
        this.bewAvg = bewAvg;
        this.imgCount = imgCount;
        this.qCount = qCount;
    }
}


export class GoogleProfileVM {
    agt: AgenturModel;
    @observable
    items: GoogleProfileBm[] = [];
    constructor(agt: AgenturModel, kvs: KvAgtMsLiteModel[]) {
        makeObservable(this);
        this.agt = agt;
        for (const kv of kvs) {

            const j = kv.j;
            const kw = new GoogleProfileBm(kv.bm, j.bew, j.bewAvg, j.imgCount, j.qCount);
            this.items.push(kw);
        }


    }

    @computed
    get bew() {
        return this.items[0].bew;
    }

    @computed
    get bewAvg() {
        return this.items[0].bewAvg;
    }

    @computed
    get imgCount() {
        return this.items[0].imgCount;
    }

    @computed
    get qCount() {
        return this.items[0].qCount;
    }
}

export class AgtGoogleProfileUiStore extends BaseUiStore<GoogleProfileVM> {
    name = 'AgtGoogleProfileUiStore';
    kgAgtGoogleProfileStore: KvAgtGoogleProfileStore;
    agenturStore: AgenturStore;

    constructor(agenturStore: AgenturStore, kgAgtGoogleProfileStore: KvAgtGoogleProfileStore) {
        super()
        makeObservable(this);
        this.kgAgtGoogleProfileStore = kgAgtGoogleProfileStore;
        this.agenturStore = agenturStore;
    }
    @observable
    bms: number[] = [];

    @computed
    get maxBm() {
        return _.max(this.bms);

    }


    @computed
    get bmOpts(): ISelectOpt[] {
        return this.bms.map((bm) => {
            return {
                value: bm,
                label: formatBM(bm),
            };
        });
    }

    @observable
    bmCurrent?: number;

    @computed
    get currentBMItems() {
        const kvVmItems: GoogleProfileVM[] = [];
        this.items.forEach((vm) => {
            const f = vm.items.filter((i) => {
                if (this.bmCurrent == i.bm) {
                    return true;
                }
                return false;
            });
            const r = new GoogleProfileVM(vm.agt, []);
            runInAction(() => {
                r.items = f;
            });
            kvVmItems.push(r);
        });

        return kvVmItems;
    }

    async _baseLoad() {
        if (this.items.length > 0) {
            return;
        }
        let data = await this.kgAgtGoogleProfileStore.findAll();
        this.bms = _.uniq(_.map(data, 'bm')).sort();
        this.bmCurrent = this.bms[0];
        const agts = await this.agenturStore.findAll();
        const res: GoogleProfileVM[] = [];
        for (const agt of agts) {
            const kvs = data.filter((d) => d.agtId === agt.agtId);
            // let kvs: KvAgtMsModel[] = [];
            // let index = 1;
            // while (index >= 0) {
            //     index = data.findIndex(a => a.agtId === agt.agtId);
            //     // let kvs ;
            //     if (index !== -1) {
            //         kvs.push(data[index]);
            //         data.splice(index, 1);
            //         // console.log('data lem', data.length, kvs.length)
            //     }
            // }

            if (kvs.length > 0) {
                const vm = new GoogleProfileVM(agt, kvs);
                res.push(vm);
            }
        }
        runInAction(() => {
            this.items = res;
        });
    }




}