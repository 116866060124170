import { useStore } from 'app/context';
import { TemplateVM } from 'app/stores/ui/template.ui.store';
import { observer } from 'mobx-react';
import { DocumentList } from '../documents/DocumentList';
import { DocumentSendModal } from '../documents/DocumentSendModal';
import { DocumentFilter } from '../documents/DocumentsFilter';
import { DocumentShareModal } from '../documents/DocumentShareModal';
import { DocumentViewerModal } from '../documents/DocumentViewerModal';
import { TemplateSelectorModal } from '../documents/TemplateSelectorModal';

export const TeamNewDokument = () => {
	const { agenturUiStore, teamUiStore, documentUiStore, uiStore } = useStore();

	const addDok = async (template: TemplateVM) => {
		let vm = await documentUiStore.addDokumentToPlan(template, agenturUiStore.current);
		await documentUiStore.share(vm.doc.id, teamUiStore.currentBnrId!);
		await documentUiStore.refresh();
		vm = documentUiStore.items.find((d) => d.id === vm.id)!;

		documentUiStore.setCurrentModalDoc(vm);
		uiStore.showModal(uiStore.modalIds.documentViewModal);
	};
	return (
		<div className="team-new-document">
			<TemplateSelectorModal onSelect={addDok} />
		</div>
	);
};

export const TeamDokumente = observer(() => {
	const { teamUiStore } = useStore();

	return (
		<div className="team-documents">
			{teamUiStore.current && (
				<>
					<div className="grid is-col-half pad-1rem">
						<div></div>
						<div className="is-left">
							<DocumentFilter bnrId={teamUiStore.currentBnrId} />
						</div>
						{/* 
						<div className="is-right">
							Disabled, because it makes things a lot easier .. 
							<DokumentUploadButton isButton={true} agt={agt} />
							<TeamNewDokument /> 
						</div>
						*/}
					</div>
					<DocumentList />
					<DocumentShareModal />
					<DocumentSendModal />
					<DocumentViewerModal showPlan={true} showAgt={false} />
				</>
			)}
		</div>
	);
});
