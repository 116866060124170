import { AgenturModel } from 'app/models/agentur.model';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { AgtPlanModel, AgtPlanStore } from '../agt.plan.store';
import { BnrDocumentModel, BnrDocumentShareModel, BnrDocumentStore, DocExportType, DocumentTextContent } from '../bnr.document.store';
import { BnrUploadModel, BnrUploadStore, IUploadOptions } from '../bnr.upload.store';
import { SessionStore } from '../session.store';
import _ from 'lodash';
import moment from 'moment';
import { DokumentShareVM, SharedDocumentStore } from '../shared.document.store';
import { TemplateUiStore, TemplateVM, UploadTemplate } from './template.ui.store';
import { AgtPlanYearVM } from './agt.plan.ui.store';
import { ApCommissionCalcContent, ApModel, ApVermittler } from 'app/models/documents/ap.model';
import { BnrPlanungsToolCommisionCalcStore } from '../bnr.planungstool.store';
import { AgtPosBjStore } from '../agt.pos.stores';
import { PersonalUiStore } from './personal.ui.store';
import { CurrentUserModel } from 'app/models/auth/current.user.model';
import { BnrTemplateStore } from '../bnr.template.store';
import { AgtPlanExportOpts } from './agt.plan.export.ui.store';
import { TeamUiStore } from './team.ui.store';
import { Steart } from 'app/models/core/steart.model';
import { BaseUiStore } from './base.ui.store';

interface IDocumentVm {
	currentUser: CurrentUserModel;
	agt?: AgenturModel;
	plan?: AgtPlanModel;
	doc: BnrDocumentModel;
	uploads: BnrUploadModel[];
}

export enum DokumentReadState {
	na = 'na',
	read = 'read',
	unread = 'unread',
}
export enum DocumentSharedState {
	sharedByMe = 'sharedByMe',
	notshared = 'notshared',
	sharedForMe = 'sharedForMe',
}

export class DocumentVM {
	constructor(opts: IDocumentVm) {
		makeObservable(this);
		this.currentUser = opts.currentUser;
		this.agt = opts.agt;
		this.plan = opts.plan;
		this.doc = opts.doc;
		this.uploads = opts.uploads;
	}
	currentUser: CurrentUserModel;
	agt?: AgenturModel;
	plan?: AgtPlanModel;

	@observable
	doc: BnrDocumentModel;

	isNew: boolean = false;

	@observable
	uploads: BnrUploadModel[] = [];

	@computed
	get uploadSize() {
		let s = 0;
		this.uploads.forEach((element) => {
			s += element.fileSize;
		});
		return s;
	}

	@computed
	get modalTitle() {
		return this.doc.title;
	}

	@computed
	get id() {
		return this.doc.id;
	}

	@computed
	get isMine() {
		if (this.currentUser.bnrId === this.doc.bnrId) {
			return true;
		}
		return false;
	}

	@computed
	get isJpg() {
		if (this.doc.category === 'jpg') {
			return true;
		}
		return false;
	}

	@computed
	get isKbv() {
		if (this.doc.category === 'kbvr') {
			return true;
		}
		return false;
	}

	@computed
	get isAp() {
		if (this.doc.category === 'ap') {
			return true;
		}
		return false;
	}
	@computed
	get isBnrGp() {
		if (this.doc.category === 'bnrgp') {
			return true;
		}
		return false;
	}

	@computed
	get isSharedBy() {
		return this.doc.bnrId !== this.currentUser.bnrId;
	}

	@computed
	get isSharedByMe() {
		return this.sharedState === DocumentSharedState.sharedByMe;
	}

	@computed
	get sharedState() {
		if (this.isMine && this.doc.sharedWith && this.doc.sharedWith.length > 0) {
			return DocumentSharedState.sharedByMe;
		}

		if (this.doc.sharedWith && this.doc.sharedWith.length === 0) {
			return DocumentSharedState.notshared;
		}
		return DocumentSharedState.sharedForMe;
	}

	@computed
	get sharedWith() {
		return this.doc.sharedWith;
	}

	@computed
	get canToggleShareRead() {
		if (!this.isSharedBy) {
			return false;
		}
		if (this.readState === DokumentReadState.read || this.readState === DokumentReadState.unread) {
			return true;
		}
		return false;
	}

	@computed
	get canDelete() {
		if (this.isSharedBy || !this.doc.editable) {
			return false;
		}
		return true;
	}

	@computed
	get canCopy() {
		if (this.isSharedBy || !this.doc.editable) {
			return false;
		}
		const notCopyCats = ['ap'];
		if (notCopyCats.includes(this.doc.category)) {
			return false;
		}

		return true;
	}

	@computed
	get canDownload() {
		if (!this.doc.id) {
			return false;
		}
		return true;
	}

	@computed
	get canShare() {
		if (this.currentUser.steart.canShareWith.length === 0) {
			return false;
		}
		return this.doc.bnrId === this.currentUser.bnrId;
	}

	@computed
	get key() {
		if (this.doc.id) {
			return this.doc.id;
		}
		return new Date().toString();
	}

	@computed
	get agtId() {
		if (this.agt) {
			return this.agt.agtId;
		}
		return undefined;
	}

	@computed
	get planYear() {
		if (this.plan) {
			return this.plan.year;
		}
		return undefined;
	}

	@computed
	get bnrGpYear() {
		if (this.doc.content) {
			const c: DocumentTextContent = this.doc.content;
			return c.bnrGpYear;
		}
		return undefined;
	}

	@computed
	get gpBnrId() {
		if (this.doc.content) {
			const c: DocumentTextContent = this.doc.content;
			return c.gpBnrId;
		}
		return undefined;
	}

	@computed
	get fullText() {
		let t = '';
		if (this.agt) {
			t += ' ' + this.agt.agtId.toString();
		}
		if (this.doc) {
			t += ' ' + this.doc.title.toLowerCase();
			if (this.doc.content.text) {
				t += ' ' + this.doc.content.text.toLowerCase();
			}
			t += ' ' + this.doc.category.toLowerCase();
			if (this.doc.sharedWith) {
				for (const share of this.doc.sharedWith) {
					t += ' ' + share.bnrId;
				}
			}
		}

		if (this.uploads) {
			for (const upload of this.uploads) {
				t += ' ' + upload.title.toLowerCase();
			}
		}

		return t;
	}

	@computed
	get readState() {
		if (!this.isSharedBy) {
			return DokumentReadState.na;
		}
		const share = this.doc.sharedWith.find((s) => s.bnrId === this.currentUser.bnrId);
		if (!share) {
			console.warn('share not found');
			return DokumentReadState.na;
		}
		if (share.dateHasRead) {
			return DokumentReadState.read;
		}
		return DokumentReadState.unread;
	}
	@computed
	get gelesen() {
		return this.readState === DokumentReadState.read;
	}
}

class DokFilter {
	constructor() {
		makeObservable(this);
	}

	@observable
	agtId?: number;
	@observable
	planYear?: number;
	@observable
	fullText: string = '';

	@observable
	bnrId?: number;

	@observable
	onlyUnread: boolean = false;

	@observable
	onlySharedForMe: boolean = false;

	@observable
	onlySharedByMe: boolean = false;

	@observable
	notShared: boolean = false;

	@observable
	withUploads: boolean = false;

	@observable
	isJpg: boolean = false;

	@observable
	isAp: boolean = false;

	@observable
	isBnrGp: boolean = false;

	@observable
	bnrGpYear?: number;

	@observable
	gpBnrId?: number;

	@action
	reset() {
		this.agtId = undefined;
		this.planYear = undefined;
		this.resetScoped();
	}
	@action
	resetScoped() {
		this.resetScopedNotLocalStorage();

		this.loadFromLocalStorage();
	}
	@action
	resetScopedNotLocalStorage() {
		this.fullText = '';
		this.onlyUnread = false;
		this.onlySharedForMe = false;
		this.onlySharedByMe = false;
		this.notShared = false;
		this.withUploads = false;
		this.isJpg = false;
		this.isAp = false;
		this.isBnrGp = false;
		this.bnrGpYear = undefined;
		this.gpBnrId = undefined;
	}

	@action
	loadFromLocalStorage() {
		const j = localStorage.getItem('dokfilter');
		if (!j) {
			return;
		}
		const items = JSON.parse(j);
		this.onlyUnread = items.onlyUnread;
		this.onlySharedForMe = items.onlySharedForMe;
		this.onlySharedByMe = items.onlySharedByMe;
		this.notShared = items.notShared;
		this.withUploads = items.withUploads;
		this.isJpg = items.isJpg;
		this.isAp = items.isAp;
	}

	saveToLocalStorage() {
		const items = {
			onlyUnread: this.onlyUnread,
			onlySharedForMe: this.onlySharedForMe,
			onlySharedByMe: this.onlySharedByMe,
			notShared: this.notShared,
			withUploads: this.withUploads,
			isJpg: this.isJpg,
			isAp: this.isAp,
		};
		localStorage.setItem('dokfilter', JSON.stringify(items));
	}

	@computed
	get hasOrOption() {
		if (this.isJpg || this.isAp || this.onlyUnread || this.notShared || this.onlySharedForMe || this.onlySharedByMe || this.withUploads || this.fullText.length > 0) {
			return true;
		}
		return false;
	}

	test(d: DocumentVM) {
		let t = false;
		if (this.agtId && this.agtId !== d.agtId) {
			return false;
		}
		if (this.planYear && this.planYear !== d.planYear) {
			return false;
		}

		if (this.bnrGpYear && this.bnrGpYear !== d.bnrGpYear) {
			return false;
		}

		if (this.gpBnrId !== d.gpBnrId) {
			return false;
		}

		if (this.bnrId) {
			let inScope = false;
			if (this.bnrId === d.doc.bnrId) {
				inScope = true;
			}
			const shares = d.doc.sharedWith.map((s) => s.bnrId);
			if (shares.includes(this.bnrId)) {
				inScope = true;
			}
			if (!inScope) {
				return false;
			}
		}

		if (!this.hasOrOption) {
			return true;
		}

		if (this.isJpg && d.isJpg) {
			t = true;
		}
		if (this.isAp && d.isAp) {
			t = true;
		}

		if (this.isBnrGp && d.isBnrGp) {
			t = true;
		}

		if (this.onlyUnread && d.readState === DokumentReadState.unread) {
			t = true;
		}
		if (this.onlySharedForMe && d.sharedState === DocumentSharedState.sharedForMe) {
			t = true;
		}
		if (this.onlySharedByMe && d.sharedState === DocumentSharedState.sharedByMe) {
			t = true;
		}
		if (this.notShared && d.sharedState === DocumentSharedState.notshared) {
			t = true;
		}
		if (this.withUploads && d.uploads.length > 0) {
			t = true;
		}

		if (this.fullText.length > 0) {
			const ft = this.fullText.toLowerCase();
			t = d.fullText.indexOf(ft) >= 0;
		}

		return t;
	}
}

export interface SendDocPerEmailData {
	to: string;
	cc: string;
	subject: string;
	body: string;
}
export interface IShareData {
	shares: DokumentShareVM[];
	nonShareables: DokumentShareVM[];
}

export class DocumentUiStore extends BaseUiStore<DocumentVM> {
	name = 'DocumentUiStore';
	bnrDocumentStore: BnrDocumentStore;
	bnrUploadStore: BnrUploadStore;
	sharedDocumentStore: SharedDocumentStore;
	session: SessionStore;
	agenturStore: AgenturStore;
	agtPlanStore: AgtPlanStore;
	bnrPlanungsToolCommisionCalcStore: BnrPlanungsToolCommisionCalcStore;
	agtPosBjStore: AgtPosBjStore;
	personalUiStore: PersonalUiStore;
	templateUiStore: TemplateUiStore;
	teamUiStore: TeamUiStore;
	constructor(
		session: SessionStore,
		bnrDocumentStore: BnrDocumentStore,
		bnrUploadStore: BnrUploadStore,
		sharedDocumentStore: SharedDocumentStore,
		agenturStore: AgenturStore,
		agtPlanStore: AgtPlanStore,
		bnrPlanungsToolCommisionCalcStore: BnrPlanungsToolCommisionCalcStore,
		agtPosBjStore: AgtPosBjStore,
		personalUiStore: PersonalUiStore,
		bnrTemplateStore: BnrTemplateStore,
		teamUiStore: TeamUiStore,
	) {
		super();
		makeObservable(this);
		this.session = session;
		this.bnrDocumentStore = bnrDocumentStore;
		this.bnrUploadStore = bnrUploadStore;
		this.sharedDocumentStore = sharedDocumentStore;
		this.agenturStore = agenturStore;
		this.agtPlanStore = agtPlanStore;
		this.bnrPlanungsToolCommisionCalcStore = bnrPlanungsToolCommisionCalcStore;
		this.agtPosBjStore = agtPosBjStore;
		this.personalUiStore = personalUiStore;
		this.teamUiStore = teamUiStore;
		// hack to avoid circular dependency..
		this.templateUiStore = new TemplateUiStore(bnrTemplateStore);

		this.agtPosBjStore.registerPosIds([34]);
	}

	@observable
	currentFilter: DokFilter = new DokFilter();

	@computed
	get currentItems() {
		let items = this.items.map((i) => i);
		const f = this.currentFilter;
		if (!f) {
			return items;
		}
		items = items.filter((i) => {
			return f.test(i);
		});

		return _.sortBy(items, (r) => r.doc.modified).reverse();
	}

	@observable current?: DocumentVM;
	@computed
	get currentDocId() {
		if (this.current) {
			return this.current.id;
		}
		return undefined;
	}

	@computed
	get sharedItemsCurrentYearUnread(): DocumentVM[] {
		return this.sharedItemsCurrentYear.filter((d) => !d.gelesen);
	}
	@computed
	get sharedCountCurrentYearUnread() {
		return this.sharedItemsCurrentYearUnread.length;
	}

	@computed
	get sharedItemsCurrentYear(): DocumentVM[] {
		const year = moment().year();
		const items = this.items.filter((d) => d.isSharedBy && d.doc.yearModified === year);
		return items;
	}
	@computed
	get sharedCountCurrentYear() {
		return this.sharedItemsCurrentYear.length;
	}

	@computed
	get sharedChangedOrNewSinceLastWeek() {
		let kw = moment().week();
		if (kw === 1) {
			return 0;
		}
		kw = kw - 1;
		const year = moment().year().toString();
		let kws = year + kw;
		if (kw < 10) {
			kws = year + '0' + kw;
		}

		const items = this.sharedItemsCurrentYear.filter((d) => d.doc.kwModified === kws);
		return items.length;
	}

	@action
	setCurrent(doc?: DocumentVM) {
		this.current = doc;
	}

	@observable
	currentModalDoc?: DocumentVM;

	@action
	setCurrentModalDoc(doc?: DocumentVM) {
		this.currentModalDoc = doc;
	}
	@action
	setPreviousModalDoc() {
		if (!this.currentModalDoc || this.currentItems.length === 0) {
			return;
		}
		let i = this.currentItems.findIndex((d) => d.id === this.currentModalDoc!.id);
		if (i === 0) {
			i = this.currentItems.length;
		}
		const d = this.currentItems[i - 1];
		this.setCurrentModalDoc(d);
	}

	@action
	setNextModalDoc() {
		if (!this.currentModalDoc || this.currentItems.length === 0) {
			return;
		}
		let i = this.currentItems.findIndex((d) => d.id === this.currentModalDoc!.id);
		if (i === this.currentItems.length) {
			i = 0;
		}
		const d = this.currentItems[i + 1];
		this.setCurrentModalDoc(d);
	}



	@computed
	get last5() {
		const shared = this.currentItems;
		const items = _.sortBy(shared, (i) => i.doc.modified).reverse();
		return items.slice(0, 4);
	}

	@computed
	get last5ByTeam() {
		if (!this.teamUiStore.currentBnrId) {
			return [];
		}
		let shared = this.items.filter((d) => d.doc.sharedWith.map((s) => s.bnrId).includes(this.teamUiStore.currentBnrId!) || d.doc.bnrId === this.teamUiStore.currentBnrId!);
		const items = _.sortBy(shared, (i) => i.doc.modified).reverse();
		return items.slice(0, 4);
	}

	@computed
	get last5Shared() {
		const shared = this.currentItems.filter((i) => i.isSharedBy);
		const items = _.sortBy(shared, (i) => i.doc.modified).reverse();
		return items.slice(0, 4);
	}

	async _baseLoad() {

		this.bnrUploadStore.clearCache();

		const fetch = await Promise.all([this.agenturStore.findAll(), this.agtPlanStore.findAll(), this.bnrDocumentStore.findAll(), this.bnrUploadStore.findAll()]);

		const agts = fetch[0];
		const plans = fetch[1];
		const docs = fetch[2];
		const uploads = fetch[3];

		const res: DocumentVM[] = [];

		const isSpezi = this.agenturStore.session.currentUser!.isSpezi;

		docs.forEach((d) => {
			if (d.category.endsWith('_old') || d.category === 'user_notiz') {
				return;
			}
			const p = plans.find((p) => p.year === d.planYear);
			const docUploads = uploads.filter((u) => u.documentId === d.id);
			const vm = new DocumentVM({
				currentUser: this.session.currentUser!,
				doc: d,
				plan: p,
				agt: agts.find((a) => a.agtId === d.agtId),
				uploads: docUploads,
			});
			if (d.category === 'ap') {
				const ap = d.content as ApModel;
				if (!ap.hasValidId && vm.agt) {
					ap.setProvsysId(vm.agt);
				}
			}

			if (d.category === 'ap' && isSpezi && vm.isMine) {
				// nur VBLS dürfen Planungstool sehen.
				return;
			}

			res.push(vm);
		});
		runInAction(() => {
			this.items = res;
		});

	}

	async findAll() {
		return this.load();
	}

	async findByAgtYear(agtId: number, year: number) {
		await this.load();
		return this.items.filter((i) => i.agtId === agtId && i.planYear === year);
	}

	_ensurInPlanId?: string;
	async ensureJpgPlanungDocs(plan: AgtPlanYearVM) {
		if (this._ensurInPlanId === plan.plan.id) {
			return;
		}
		if (this.session.currentUser!.isMulti) {
			return;
		}
		this._ensurInPlanId = plan.plan.id;

		// should be called after load()
		let jpg: DocumentVM | undefined;
		if (plan.plan.metadata.jpgDocId) {
			jpg = this.items.find((d) => d.id === plan.plan.metadata.jpgDocId);
			if (jpg) {
				this._ensurInPlanId = undefined;
				return;
			}
		}
		jpg = this.items.find((d) => d.agtId === plan.agtId && d.planYear === plan.year && d.doc.category === 'jpg' && d.isMine);
		if (jpg) {
			plan.plan.metadata.jpgDocId = jpg.id;
			await this.agtPlanStore.save(plan.plan);
			this._ensurInPlanId = undefined;
			return;
		}
		let docJpg = new BnrDocumentModel({
			id: undefined,
			agtId: plan.agtId,
			bnrId: this.session.currentUser!.bnrId,
			category: 'jpg',
			planId: plan.plan.id,
			created: new Date(),
			modified: new Date(),
			editable: true,
			title: 'Jahresgespräch',
			status: 0,
			text: '',
		});
		// don't use this.save to avoid refresh-load
		docJpg = await this.bnrDocumentStore.save(docJpg);
		docJpg.editable = true;
		jpg = new DocumentVM({
			currentUser: this.session.currentUser!,
			agt: plan.agt,
			plan: plan.plan,
			doc: docJpg,
			uploads: [],
		});
		plan.plan.metadata.jpgDocId = jpg.id;
		await this.agtPlanStore.save(plan.plan);
		runInAction(() => {
			this.items.push(jpg!);
		});
		this._ensurInPlanId = undefined;
	}
	async ensurePlanungsToolDoc(plan: AgtPlanYearVM) {
		if (this._ensurInPlanId === plan.plan.id) {
			return;
		}
		const isMulti = this.agenturStore.session.currentUser!.isMulti;
		const isSpezi = this.agenturStore.session.currentUser!.isSpezi;
		if (isSpezi || isMulti) {
			return;
		}

		this._ensurInPlanId = plan.plan.id;

		// should be called after load()
		let ap: DocumentVM | undefined;
		if (plan.plan.metadata.apDocId) {
			ap = this.items.find((d) => d.id === plan.plan.metadata.apDocId);
			if (ap) {
				this._ensurInPlanId = undefined;
				return;
			}
		}
		ap = this.items.find((d) => d.agtId === plan.agtId && d.planYear === plan.year && d.doc.category === 'ap' && d.isMine);
		if (ap) {
			plan.plan.metadata.apDocId = ap.id;
			await this.agtPlanStore.save(plan.plan);
			this._ensurInPlanId = undefined;
			return;
		}
		let docAp = new BnrDocumentModel({
			id: undefined,
			agtId: plan.agtId,
			bnrId: this.session.currentUser!.bnrId,
			category: 'ap',
			planId: plan.plan.id,
			created: new Date(),
			modified: new Date(),
			editable: true,
			title: 'Planungstool',
			status: 0,
			text: '',
		});
		const agt = await this.agenturStore.findById(plan.agtId);
		docAp.content = new ApModel({
			agtId: plan.agtId,
			commissionCalcContent: {
				id: agt!.apProvsysId,
			},
		});
		// don't use this.save to avoid refresh-load
		docAp = await this.bnrDocumentStore.save(docAp);
		docAp.editable = true;
		ap = new DocumentVM({
			currentUser: this.session.currentUser!,
			agt: plan.agt,
			plan: plan.plan,
			doc: docAp,
			uploads: [],
		});
		plan.plan.metadata.apDocId = ap.id;
		await this.agtPlanStore.save(plan.plan);
		runInAction(() => {
			this.items.push(ap!);
		});
		this._ensurInPlanId = undefined;
	}

	@action
	async createUploadDokument(title: string, agtId?: number, planId?: string) {
		let agt, plan;
		if (agtId) {
			agt = await this.agenturStore.findById(agtId);
		}
		if (planId) {
			plan = await this.agtPlanStore.findById(planId);
		}
		const dokument = await this.addDokumentToPlan(UploadTemplate, agt, plan);
		runInAction(() => {
			dokument.doc.title = title;
		});
		return await this.save(dokument);
	}

	@action
	async addDokumentToPlan(template: TemplateVM, agt?: AgenturModel, plan?: AgtPlanModel) {
		const planId = plan ? plan.id : undefined;
		const agtId = agt ? agt.agtId : undefined;

		const doc = new BnrDocumentModel({
			id: undefined,
			agtId: agtId,
			bnrId: this.session.currentUser!.bnrId,
			category: template.template.content.category,
			planId: planId,
			created: new Date(),
			modified: new Date(),
			editable: true,
			title: template.title,
			status: 0,
			text: template.template.content.text,
			// contentJson: '{}',
		});
		if (template.template.id === 'planungstool') {
			this.apNewPlanungstool(doc, agt!);
		}

		let vm = new DocumentVM({
			currentUser: this.session.currentUser!,
			agt,
			plan,
			doc,
			uploads: [],
		});
		vm = (await this.save(vm))!;
		runInAction(() => {
			vm.isNew = true;
			this.current = vm;
		});
		return vm;
	}

	@action
	async undoAdd() {
		if (this.current) {
			await this.deleteById(this.current!.id);
			this.setCurrent();
		}
	}

	async save(model: DocumentVM) {
		model.isNew = false;
		const doc = await this.bnrDocumentStore.save(model.doc);
		// force reload, so that also new documents appear in the list
		await this.refresh();
		return this.items.find((i) => i.id === doc.id);
	}

	@action
	async refresh() {
		// force reload, so that also new documents appear in the list
		this.clearCache();
		this.bnrDocumentStore.clearCache();
		this.bnrUploadStore.loaded = false;
		await this.load();
	}

	@action
	async deleteById(id: string) {
		await this.bnrDocumentStore.deleteById(id);
		runInAction(() => {
			this.items = this.items.filter((d) => d.id !== id);
			this.setCurrent(undefined);
		});
	}

	@action
	async copyDocument(id: string) {
		const doc = this.items.find((d) => d.id === id);
		if (!doc) {
			return;
		}
		const copy = new BnrDocumentModel({
			id: undefined,
			agtId: doc.agt?.agtId,
			bnrId: doc.doc.bnrId,
			category: doc.doc.category,
			planId: doc.plan?.id,
			planYear: doc.planYear,
			created: new Date(),
			modified: new Date(),
			editable: true,
			title: 'Kopie von ' + doc.doc.title,
			status: 0,
			contentJson: doc.doc.content.toJSON(),
		});
		const vm = new DocumentVM({
			currentUser: this.session.currentUser!,
			agt: doc.agt,
			plan: doc.plan,
			doc: copy,
			uploads: [],
		});
		this.items.push(vm);
		this.setCurrent(vm);
	}

	async download(doc: DocumentVM) {
		const fileName = moment(doc.doc.modified).format('YYYYMMDD') + '-AGENTUR-' + _.snakeCase(doc.doc.title) + '.pdf';
		const windowReference = window.open();
		if (!windowReference) {
			return;
		}
		windowReference.opener = null;
		let pdfUrl;
		if (doc.isKbv) {
			pdfUrl = await this.bnrDocumentStore.downloadDocAsPdf(doc.id, fileName, DocExportType.kbv);
		} else if (doc.isAp) {
			// ap: excel + pdf as a zip
			const docs = [doc];
			const planvm = new AgtPlanYearVM({ agt: doc.agt!, plan: doc.plan! });
			await this.templateUiStore.findAll();
			const exp = new AgtPlanExportOpts(planvm, docs, this.templateUiStore.items, undefined);
			exp.selectedDocTypes.forEach((dt) => (dt.selected = true));
			this.session.incApiCallCount();
			await this.bnrDocumentStore.multiDocExport(exp).finally(() => {
				this.session.decApiCallCount();
			});
			return;
		} else {
			pdfUrl = await this.bnrDocumentStore.downloadDocAsPdf(doc.id, fileName, DocExportType.agt);
		}
		windowReference.location = pdfUrl as any;
	}

	async sendDocumentPerEmail(id: string, data: SendDocPerEmailData) {
		return await this.bnrDocumentStore.sendDocumentPerEmail(id, data);
	}

	getSharesForDocument(document: DocumentVM, filterByRole: boolean) {
		let users = this.teamUiStore.items.map((vm) => vm.user);
		if (document.agt) {
			users = document.agt.users;
		}

		let shares = this.sharedDocumentStore.getSharesForDocument(document, users, filterByRole);

		if (document.gpBnrId && document.gpBnrId > 0) {
			// gpBnr Document can only between ldp and edp/edt
			const targetBnrId = document.gpBnrId;
			if (document.currentUser.steart.steart === Steart.EDP || document.currentUser.steart.steart === Steart.EDT) {
				shares = _.filter(shares, (s) => {
					return s.user.steart.steart === Steart.LDP;
				});
			}
			if (document.currentUser.steart.steart === Steart.LDP) {
				shares = _.filter(shares, (s) => {
					return s.user.bnrId === targetBnrId;
				});
			}
		}

		// remove self
		shares = _.filter(shares, (s) => {
			return s.user.bnrId !== document.currentUser.bnrId;
		});

		// split users without bensl (i.e. cannot access dvs)
		let nonShareables = _.filter(shares, (s) => {
			return !s.user.bensl;
		});
		shares = _.filter(shares, (s) => {
			return !!s.user.bensl;
		});

		shares = _.sortBy(shares, (s) => s.user.steart.sortOrder);
		nonShareables = _.sortBy(nonShareables, (s) => s.user.steart.sortOrder);

		return {
			shares,
			nonShareables,
		};
	}

	async share(docId: string, bnrId: number) {
		const res = await this.sharedDocumentStore.share(docId, [bnrId]);
		const share = res.data.dtos.find((d: any) => d.bnrId === bnrId);
		if (share) {
			return new BnrDocumentShareModel(share);
		}
	}

	async deleteUpload(document: DocumentVM, upload: BnrUploadModel) {
		await this.bnrUploadStore.deleteById(upload.id);
		runInAction(() => {
			document.uploads = document.uploads.filter((u) => u.id !== upload.id);
		});
	}

	async uploadFile(file: any, opts: IUploadOptions, onProgres: (p: number) => void) {
		const upload = await this.bnrUploadStore.uploadFile(file, opts, onProgres);
		if (upload.documentId) {
			const dok = this.items.find((i) => i.doc.id === upload.documentId);
			if (dok) {
				runInAction(() => {
					dok.uploads.push(upload);
				});
			}
		}
	}

	// AP / Planungstool

	@observable
	currentVermittler?: ApVermittler;

	@action
	setCurrentVermittler(v?: ApVermittler) {
		this.currentVermittler = v;
	}

	@action
	async apEnsureProps(doc: DocumentVM) {
		if (doc.id !== this.currentDocId) {
			return;
		}
		await this.apEnsureBestand(doc);
		await this.apEnsureVermittler(doc);
	}
	@action
	async apEnsureBestand(doc: DocumentVM) {
		const ap = doc.doc.content as ApModel;
		if (ap.commissionCalcContent.bestand > 0) {
			return;
		}
		const allitems = await this.agtPosBjStore.findAll();
		var jahr = doc.planYear! * 100 + 12;
		var vj = (doc.planYear! - 1) * 100 + 12;
		// find vj
		let item = allitems.find((i) => i.agtId === doc.agtId && i.posId === 34 && i.bm === vj);
		if (item) {
			ap.commissionCalcContent.bestand = item.ergBt;
			return;
		}
		// fallback / aktuelles jahr
		item = allitems.find((i) => i.agtId === doc.agtId && i.posId === 34 && i.bm === jahr);
		if (item) {
			ap.commissionCalcContent.bestand = item.ergBt;
			return;
		}
	}

	@action
	apNewPlanungstool(doc: BnrDocumentModel, agt: AgenturModel) {
		doc.content = new ApModel({
			agtId: doc.agtId,
			commissionCalcContent: {
				id: agt.apProvsysId,
			},
		});
	}

	@action
	async apEnsureVermittler(doc: DocumentVM) {
		// const personal = await this.personalUiStore.findByAgtId(doc.agtId!);
		await this.personalUiStore.load();
		const teilhaber = this.personalUiStore.teilhaber._items.filter((x) => x.agtId === doc.agtId);
		// const mitarbeiter = this.personalUiStore.custom._items.filter((x) => x.agtId === doc.agtId);
		const zweitverkauefer = this.personalUiStore.zweitverkauefer._items.filter((x) => x.agtId === doc.agtId);

		const ap = doc.doc.content as ApModel;
		const vermittler: ApVermittler[] = [];
		for (const th of teilhaber) {
			let verm = ap.vermittler.find((v) => v.id === th.identifier);
			let _verm = new ApVermittler({
				id: th.identifier,
				art: 'Vertreter',
				kat: 'teilhaber',
				name: th.fullName,
				bensl: '',
				// bnr: th.bnr,
				// bnrId: p.bnrId,
			});
			if (verm) {
				_verm.plan = verm.plan;
				_verm.absence = verm.absence;
				_verm.quoteAnzahlNeustueckJeTermin = verm.quoteAnzahlNeustueckJeTermin;
				_verm.quoteDurchgefuehrteTermine = verm.quoteDurchgefuehrteTermine;
				_verm.quoteDurchschnittBewertungNeutsueck = verm.quoteDurchschnittBewertungNeutsueck;
				_verm.quoteFolgetermine = verm.quoteFolgetermine;
			}
			vermittler.push(_verm);
		}

		const nichtVermittler: ApVermittler[] = [];

		const allowedZweitverkaeufer = ['KBV', 'KBVIA', 'KBVE', 'KBVPM', 'ATV KBV', 'ATVKBV', 'AGP', 'KB', 'HVIE'];
		for (const zw of zweitverkauefer) {
			if (!allowedZweitverkaeufer.includes(zw.kat.toUpperCase()) || !zw.bnr) {
				let _verm = new ApVermittler({
					id: zw.identifier,
					art: 'nope',
					kat: zw.kat,
					name: zw.name,
					bensl: '',
					bnr: zw.bnr,
					// bnrId: p.bnrId,
				});
				nichtVermittler.push(_verm);
			}

			let verm = ap.vermittler.find((v) => v.bnr === zw.bnr);

			// art bestimmt den dropdown vermittlerart im exxel
			let art = zw.kat.toUpperCase();
			if (art.startsWith('KBV') || art.startsWith('ATV')) {
				art = 'KBV';
			}
			if (art.indexOf('AGP') >= 0) {
				art = 'AGP';
			}

			let _verm = new ApVermittler({
				id: zw.identifier,
				art,
				kat: zw.kat,
				name: zw.name,
				bensl: '',
				bnr: zw.bnr,
				// bnrId: p.bnrId,
			});
			if (verm) {
				_verm.plan = verm.plan;
				_verm.absence = verm.absence;
				_verm.quoteAnzahlNeustueckJeTermin = verm.quoteAnzahlNeustueckJeTermin;
				_verm.quoteDurchgefuehrteTermine = verm.quoteDurchgefuehrteTermine;
				_verm.quoteDurchschnittBewertungNeutsueck = verm.quoteDurchschnittBewertungNeutsueck;
				_verm.quoteFolgetermine = verm.quoteFolgetermine;
			}
			vermittler.push(_verm);
		}

		runInAction(() => {
			ap.vermittler = vermittler.concat(nichtVermittler);
		});

		// alle teilhaber
		// keine mitarbeiter
		// alle zweiterkauefer mit einer 4 ziffrigen bnrB (B!)

		// zweitverkaufer nur die bnr mit 4 ziffern nehmen.

		// for (const p of personal) {
		// 	// if (p._kat === 'custom') {
		// 	// 	continue;
		// 	// }
		// 	let verm = ap.vermittler.find((v) => v.id === p.apId);
		// 	if (!verm) {
		// 		verm = new ApVermittler({
		// 			id: p.apId,
		// 			art: p.apKat,
		// 			kat: 'vertreter',
		// 			name: p.fullName,
		// 			bensl: p.apBensl,
		// 			bnr: p.bnr,
		// 			bnrId: p.bnrId,
		// 		});
		// 		runInAction(() => {
		// 			ap.vermittler.push(verm!);
		// 		});
		// 	}
		// 	verm.bnr = p.bnr ? p.bnr : p.bnrId;
		// }
		// ap.vermittler.forEach((v) => {
		// 	if (!v.isSalesPerson) {
		// 		v.plan = 0;
		// 	}
		// });
	}

	async apCalcCommision(doc: ApCommissionCalcContent) {
		return await this.bnrPlanungsToolCommisionCalcStore.apCalcCommision(doc as any);
	}
	async apCalcGoalsForAgency(doc: ApModel) {
		const goals = await this.bnrPlanungsToolCommisionCalcStore.apGoalsCalc(doc);
		return goals.find((g) => g.name === 'agency');
	}

	async apCalcGoalsForVermittler(doc: ApModel, vermittler: ApVermittler) {
		const goals = await this.bnrPlanungsToolCommisionCalcStore.apGoalsCalc(doc, vermittler);
		return goals.find((g) => g.name === vermittler.id);
	}
}
