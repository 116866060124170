import { SelectInput } from 'app/components/common/Select';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { AgtFitnessVm, FitModel } from 'app/stores/ui/agt.fitness.ui.store';



export const FitnessTrackerListe = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const history = useHistory();
	const [hasItems, setHasItems] = useState(false);

	const simpleFitCell = (f: FitModel) => {
		return <>{f.ergebnis} / {f.ziel}</>
	}


	const tm = useMemo(() => {
		const tm = new TableModel<AgtFitnessVm, number>();
		const cols: ITableColumn<AgtFitnessVm, number>[] = [];

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
			render: AgenturNameCell,
		});

		// GOOGLE 
		cols.push({
			label: 'bew',
			path: `bew.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.anzBew)
			},
		});
		cols.push({
			label: 'bewAvg',
			path: `bewAvg.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.bewAvg)
			},
		});
		cols.push({
			label: 'imgCount',
			path: `imgCount.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.imgCount)
			},
		});

		cols.push({
			label: 'qCount',
			path: `qCount.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.qCount)
			},
		});


		/// AMIS.NOW 


		/// AKUMA 
		cols.push({
			label: 'isAkumaPrimeUser',
			path: `isAkumaPrimeUser`,
		});

		//  --- PLANER ---
		cols.push({
			label: 'planerAnzahlWochenNutzung',
			path: `planerAnzahlWochenNutzung.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.planerAnzahlWochenNutzung)
			},
		});
		cols.push({
			label: 'planerUebertragsungquoteAmisNow',
			path: `planerUebertragsungquoteAmisNow.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.planerUebertragsungquoteAmisNow)
			},
		});

		//  --- PRIME ---
		cols.push({
			label: 'primeEinordnungsquote',
			path: `primeEinordnungsquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.primeEinordnungsquote)
			},
		});

		cols.push({
			label: 'primeAbwahlquote',
			path: `primeAbwahlquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.primeAbwahlquote)
			},
		});
		cols.push({
			label: 'primeTerminierungExtern',
			path: `primeTerminierungExtern.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.primeTerminierungExtern)
			},
		});


		/// Meine Allianz 
		cols.push({
			label: 'mazBestandDurchdringungsquote',
			path: `mazBestandDurchdringungsquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.mazBestandDurchdringungsquote)
			},
		});
		cols.push({
			label: 'mazNeuDurchdringungsquote',
			path: `mazNeuDurchdringungsquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.mazNeuDurchdringungsquote)
			},
		});


		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';



		return tm;
	}, [history]);

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [
		tm,
		berichteUiiStore.selectedAgt,
		agenturListUiStore.filterHasChanged,
		agenturListUiStore,
		agtFitnessUiStore.items,
		agtFitnessUiStore,
	]);

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Fitnesstrackerdaten gefunden</div>
			) : (
				<>

					<Table stickyHeader={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});
