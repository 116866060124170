import { AgenturModel } from 'app/models/agentur.model';
import { computed, runInAction } from 'mobx';
import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { AgtZielModel, AgtZielStore } from '../agt.ziel.store';
import { SessionStore } from '../session.store';
import { BaseUiStore } from './base.ui.store';
import { BPMAXYEAR, BPMINYEAR } from './berichte.ui.store';

export class RingPosDef {
	constructor(opts: any) {
		this.name = opts.name;
		this.goldPosId = opts.goldPosId;
		this.silberPosId = opts.silberPosId;
		this.bronzePosId = opts.bronzePosId;
	}
	name: string;
	goldPosId: number;
	silberPosId: number;
	bronzePosId: number;
}

interface IAgtRingPos {
	pos: RingPosDef;
	agt: AgenturModel;
	gold?: AgtZielModel;
	silber?: AgtZielModel;
	bronze?: AgtZielModel;
	year: number;
	datenStand?: Date;
}

export class AgtRingPos {
	pos: RingPosDef;
	agt: AgenturModel;
	gold?: AgtZielModel;
	silber?: AgtZielModel;
	bronze?: AgtZielModel;
	year: number;
	month: number = 1;
	constructor(opts: IAgtRingPos) {
		this.pos = opts.pos;
		this.agt = opts.agt;
		this.gold = opts.gold;
		this.silber = opts.silber;
		this.bronze = opts.bronze;
		this.year = opts.year;
		if (opts.datenStand) {
			this.month = moment(opts.datenStand).month() + 1;
		}
	}

	@computed
	get ergebnis() {
		if (this.gold) {
			return this.gold.ergebnis;
		}
		return undefined;
	}
	@computed
	get hasGold() {
		return this.gold && this.gold.ziel > 0 && this.gold.ziel <= this.gold.ergebnis;
	}
	@computed
	get hasSilber() {
		return this.silber && this.silber.ziel > 0 && this.silber.ziel <= this.silber.ergebnis;
	}
	@computed
	get hasBronze() {
		return this.bronze && this.bronze.ziel > 0 && this.bronze.ziel <= this.bronze.ergebnis;
	}
}

class CacheItem {
	constructor(year: number, items: AgtRingPos[]) {
		this.items = items;
		this.year = year;
		this.loadingPromise = new Promise((resolve) => {
			this._resolver = resolve;
		});
	}
	markAsLoaded = () => {
		if (this._resolver) {
			this._resolver();
		}
	};
	_resolver: any;
	year: number;
	items: AgtRingPos[];
	loadingPromise: Promise<any>;
}

export class AgtRingOverview {
	agt: AgenturModel;

	constructor(agt: AgenturModel, agtRingPos: AgtRingPos[]) {
		this.agt = agt;
		this.goldCount = agtRingPos.filter((i) => i.hasGold).length;
		this.silberCount = agtRingPos.filter((i) => i.hasSilber).length;
		this.bronzeCount = agtRingPos.filter((i) => i.hasBronze).length;
		this.agtRingPos = agtRingPos;
	}
	agtRingPos: AgtRingPos[];

	goldCount: number = 0;
	silberCount: number = 0;
	bronzeCount: number = 0;

	@computed
	get achievedMedal() {
		if (this.goldCount >= 5) {
			return 'gold';
		}
		if (this.silberCount >= 5) {
			return 'silber';
		}
		if (this.bronzeCount >= 5) {
			return 'bronze';
		}
		return undefined;
	}
}

export const RingPosGroups: RingPosDef[] = [
	{ name: 'P-Sach o. Kr./Unf. N/M-BT', goldPosId: 5510100, silberPosId: 5510200, bronzePosId: 5510300 },
	{ name: 'Unfall Beitrag o. Anp', goldPosId: 5510110, silberPosId: 5510210, bronzePosId: 5510310 },
	{ name: 'Kraft-Haft-PKW lf.Nst.', goldPosId: 5510120, silberPosId: 5510220, bronzePosId: 5510320 },
	{ name: 'Firmen Sach o. Kr. NM-BT', goldPosId: 5510130, silberPosId: 5510230, bronzePosId: 5510330 },
	{ name: 'Leben BS o. ZE', goldPosId: 5510140, silberPosId: 5510240, bronzePosId: 5510340 },
	{ name: 'Leben KNP', goldPosId: 5510150, silberPosId: 5510250, bronzePosId: 5510350 },
	{ name: 'Fonds bew. Anlagesumme', goldPosId: 5510160, silberPosId: 5510260, bronzePosId: 5510360 },
	{ name: 'Kranken MB o. Dyn.', goldPosId: 5510170, silberPosId: 5510270, bronzePosId: 5510370 },
];

export class AgtRingUiStore extends BaseUiStore<CacheItem> {
	name = 'AgtRingUiStore';
	session: SessionStore;
	agenturStore: AgenturStore;
	posAgtZielStore: AgtZielStore;
	constructor(session: SessionStore, agenturStore: AgenturStore, posAgtZielStore: AgtZielStore) {
		super();
		this.session = session;
		this.agenturStore = agenturStore;
		this.posAgtZielStore = posAgtZielStore;
		this.posAgtZielStore.registerPosIds(this.ringPosIds);
	}

	get ringPosIds() {
		const ids: number[] = [];
		this.ringPosGroups.forEach((p) => {
			ids.push(p.bronzePosId);
			ids.push(p.silberPosId);
			ids.push(p.goldPosId);
		});
		return ids;
	}

	get ringPosGroups(): RingPosDef[] {
		return RingPosGroups;
	}

	// items: [number, AgtRingPos[]] = [0,[]];


	cache: CacheItem[] = [];

	async _baseLoad() {
		for (let y = BPMAXYEAR; y >= BPMINYEAR; y--) {
			await this.fillCache(y);
		}
	}

	async fillCache(year: number) {
		let cacheItem = this.cache.find((c) => c.year === year);
		if (cacheItem) {
			await cacheItem.loadingPromise;
			return cacheItem.items;
		}
		cacheItem = new CacheItem(year, []);

		const p = await Promise.all([this.agenturStore.findAll(), this.posAgtZielStore.findAll()]);
		const agts = p[0];
		let allAgtPos = p[1];
		allAgtPos = allAgtPos.filter((p) => p.year === year);

		const ringPos = this.ringPosGroups;
		const relevantPosIds: number[] = [];
		ringPos.forEach((pos) => {
			relevantPosIds.push(pos.goldPosId);
			relevantPosIds.push(pos.silberPosId);
			relevantPosIds.push(pos.bronzePosId);
		});

		// a lot faster for users with many agts
		allAgtPos = allAgtPos.filter((p) => relevantPosIds.includes(p.posId));
		const datenStand = this.posAgtZielStore.getDatenStandByYear(year);

		const res: AgtRingPos[] = [];
		agts.forEach((agt) => {
			ringPos.forEach((pos) => {
				let agtPos;
				[agtPos, allAgtPos] = this.filterAndSplice(allAgtPos, (p) => p.agtId === agt.agtId);
				const gold = agtPos.find((p) => p.posId === pos.goldPosId);
				const silber = agtPos.find((p) => p.posId === pos.silberPosId);
				const bronze = agtPos.find((p) => p.posId === pos.bronzePosId);
				const agtRingPos = new AgtRingPos({
					agt,
					pos,
					gold,
					silber,
					bronze,
					year,
					datenStand,
				});
				res.push(agtRingPos);
			});
		});

		runInAction(() => {
			cacheItem!.items = res;
			this.cache.push(cacheItem!);
		});
		await cacheItem._resolver();
		return res;
	}

	async findAll(year: number) {
		await this.load();
		let cacheItem = this.cache.find((c) => c.year === year);
		return cacheItem!.items;

	}

	async findByGoldPosId(goldPosId: number, year: number): Promise<AgtRingPos[]> {
		const items = await this.findAll(year);
		return items.filter((i) => i.pos.goldPosId === goldPosId);
	}

	async findRingOverview(year: number): Promise<AgtRingOverview[]> {
		const res: AgtRingOverview[] = [];
		const agts = await this.agenturStore.findAll();
		const items = await this.findAll(year);
		agts.forEach((agt) => {
			const agtItems = items.filter((i) => i.agt.agtId === agt.agtId);
			res.push(new AgtRingOverview(agt, agtItems));
		});
		return res;
	}
}
