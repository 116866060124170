import _ from 'lodash';
import { AgtBestandPos5YearVM } from 'app/models/berichte/agt.bestand.pos.5year.vm';
import { IAgtBestandPosDefinition } from 'app/models/berichte/agt.bestand.pos.model';
import { SessionStore } from '../session.store';
import { AgenturStore } from '../agentur.store';
import { AgtPosAktStore, AgtPosBjStore, AgtPosMsStore } from '../agt.pos.stores';
import { PosBjModel } from 'app/models/berichte/pos.bj.model';
import { BaseUiStore } from './base.ui.store';
import { runInAction } from 'mobx';

// export interface IAgtBestandPosDefinition {
// 	posId: number;
// 	title: string;
// 	zuwachsPosId?: number;
// 	bm?: Date;
// }

export const BAQNeugeschaeftPos = -1;

var posBjIds: IAgtBestandPosDefinition[] = [
	{ posId: 32, title: 'Gesamtbewertung' },
	{ posId: BAQNeugeschaeftPos, title: 'BAQ' },
	// { posId: 1, title: 'Privat o. Kraft/Unfall NM-BT' },
	// { posId: 1, title: 'Privat o.Kraft/Unfall lf.NST' },
	{ posId: 1, title: 'Privat o. Kraft/Unfall' },

	{ posId: 13, title: 'Kraft Gesamt NM-BT' },
	{ posId: 16, title: 'KH-PKW P+F' },
	{ posId: 9, title: 'Privat Unfall' },
	// { posId: 9, title: 'Privat Unfall BoAnp' },
	{ posId: 12, title: 'F-Sach o.Kr. NM-BST' },
	{ posId: 18, title: 'Leben ohne ZE BS' },
	{ posId: 29, title: 'WA-Summe' },
	{ posId: 26, title: 'Fonds BAS' },
	{ posId: 27, title: 'Bausparen BSS' },
	{ posId: 28, title: 'Baufinanzierung' },
	{ posId: 22, title: 'Kranken MB' },
];

export class AgtNeugeschaftUiStore extends BaseUiStore<AgtBestandPos5YearVM> {

	name = 'AgtNeugeschaftUiStore';
	session: SessionStore;
	agenturStore: AgenturStore;
	agtPosBjStore: AgtPosBjStore;
	agtPosMsStore: AgtPosMsStore;
	agtPosAktStore: AgtPosAktStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, agtPosBjStore: AgtPosBjStore, agtPosMsStore: AgtPosMsStore, agtPosAktStore: AgtPosAktStore) {
		super();
		this.session = session;
		this.agenturStore = agenturStore;
		this.agtPosBjStore = agtPosBjStore;
		this.agtPosMsStore = agtPosMsStore;
		this.agtPosAktStore = agtPosAktStore;

		this.agtPosBjStore.registerPosIds(posBjIds.map((x) => x.posId));
		this.agtPosMsStore.registerPosIds(posBjIds.map((x) => x.posId));
		this.agtPosAktStore.registerPosIds(posBjIds.map((x) => x.posId));
	}
	onLogout() {
		this.agenturStore.onLogout();
		this.agtPosBjStore.onLogout();
		this.agtPosMsStore.onLogout();
		this.agtPosMsStore.onLogout();
		this.agtPosAktStore.onLogout();
	}

	async find5YearHistoryByAgtId(agtId: number) {
		await this.load();
		return this.items.filter((i) => i.agtId === agtId);
	}

	async _baseLoad() {
		const res = await Promise.all([this.agenturStore.findAll(), this.agtPosBjStore.findAll()]);

		var bestandPosId = 34;
		var bewertungPosId = 32;

		const agts = res[0];
		let posBj = res[1].slice();
		const items: AgtBestandPos5YearVM[] = [];
		for (const agt of agts) {
			let agtPos: PosBjModel[];
			[agtPos, posBj] = this.filterAndSplice(posBj, (p) => p.agtId === agt.agtId);
			agtPos = agtPos.sort((a, b) => a.bm - b.bm);
			posBjIds.forEach((posDef) => {
				let poses = agtPos.filter((agtPos) => agtPos.posId === posDef.posId);
				if (posDef.posId === BAQNeugeschaeftPos) {
					poses = [];
					let bestandPos = agtPos.filter((agtPos) => agtPos.posId === bestandPosId);
					let bewPos = agtPos.filter((agtPos) => agtPos.posId === bewertungPosId);
					bewPos.forEach((bew) => {
						let best = bestandPos.find((x) => x.bm === bew.bm);
						if (!best) {
							// fallback: manchmal ist der bestand nur vom vormonat vorhanden ?!
							best = bestandPos.find((x) => x.bm === bew.bm - 1);
						}
						if (best) {
							let baq = 0;
							if (best.ergBt > 0) {
								baq = (bew.ergBt / best.ergBt) * 100;
							}
							baq = Math.round(baq * 100) / 100;
							const baqPos = new PosBjModel({
								agtId: best.agtId,
								bm: best.bm,
								ergBt: baq,
								ergSt: 0,
								posId: BAQNeugeschaeftPos,
							});
							poses.push(baqPos);
						}
					});
				}
				const item = new AgtBestandPos5YearVM(agt, { agt, ...posDef }, poses);
				items.push(item);
			});
		}
		runInAction(() => {
			this.items = items;
		})
	}
}
