import { Icon } from 'app/components/common/Icon';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { PersonalVM } from 'app/models/personal/personal.vm';
import { formatDate } from 'app/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

export const Jubelliste = observer(() => {
	const { personalUiStore, berichteUiiStore, agenturListUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<PersonalVM, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'Vorname',
				path: 'vorname',
			},
			{
				label: 'Nachname',
				path: 'nachname',
			},
			{
				label: 'Eintritt',
				path: 'beitrittDat',
				sortBy: 'beitrittDatMonthDay',
				format: formatDate,
			},
			{
				label: 'Jubiläum',
				path: 'anniversaryDate',
				sortBy: 'beitrittDatMonthDay', // same sort as above
				format: formatDate,
			},
			{
				label: 'Jahre',
				path: 'workingYears',
			},
			{
				label: 'Tage zum Jubiläum',
				path: 'nextAnnivesaryInDays',
			},
			{
				label: '',
				path: 'hasUpcomingAnniversary',
				render: (row: TableRow<PersonalVM>) => {
					return (
						<>
							{row.data.hasUpcomingAnniversary && (
								<>
									<Icon iconClass="star" />
								</>
							)}
						</>
					);
				},
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'nextAnnivesaryInDays';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		personalUiStore.load().then(() => {
			let res = personalUiStore.items;
			res = res.filter((p) => p.workingYears > 1 && p.workingYears < 99);
			res = res.filter((p) => p.hasAnniversary);
			res = res.filter((p) => p.nextAnnivesaryInDays > -2);
			res = agenturListUiStore.applyFilterOnAgt(res) as PersonalVM[];
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
		});
	}, [tm, personalUiStore, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);
	return (
		<>
			<div className="table-container">
				<Table tm={tm} stickyHeader={true}></Table>
			</div>
		</>
	);
});
