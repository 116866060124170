import { observer } from 'mobx-react';
import { AkumaPrimeListe } from './AkumaPrimeListe';
import { AkumaPrimeSchema, AgtAkumaPrimeKwVM, AkumaPrimeType } from 'app/stores/ui/agt.akumaprime.ui.store';
import { useStore } from 'app/context';
import { useEffect, useState } from 'react';
import { AkumaPrimeAgtStackedHistory } from './AkumaPrimeStackedHistory';

import { AkumaPrimeHorizontalBarChart } from './AkumaPrimeBarChart';
import _ from 'lodash';
import { AkumaPrimeOptions } from './AkumaPrimeOverview';

export const AkumaPrimeEinordnungsOverview = observer(() => {
	const { berichteUiiStore, agtAkumaPrimeUiStore, agenturListUiStore } = useStore();
	const schema = AkumaPrimeSchema.filter((s) => s.type === AkumaPrimeType.einordnung);

	const [data, setData] = useState<AgtAkumaPrimeKwVM[]>([]);

	useEffect(() => {
		agtAkumaPrimeUiStore.load().then((res) => {
			let items = agtAkumaPrimeUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = agtAkumaPrimeUiStore.filterByAgtIdandKw(agtId);
				items = _.sortBy(items, 'bm').reverse();
				setData(items);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(agtAkumaPrimeUiStore.currentKWItems) as AgtAkumaPrimeKwVM[];
				setData(items);
			}
			// tm.sort(); // important -> otherwise the charts labels will not match the rows.
		});
	}, [agtAkumaPrimeUiStore, agtAkumaPrimeUiStore.currentKWItems, berichteUiiStore.selectedAgt, berichteUiiStore.menu, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	return (
		<>
			<div className="table-container pad-bottom-3rem">
				<AkumaPrimeOptions />

				{data.length === 0 ? (
					<>
						<div className="pad-1rem content">
							<h3 className="title is-5">Keine Daten</h3>
							<p>Keine AKUMA Prime teilnahme.</p>
						</div>
					</>
				) : (
					<div className="table-container pad-bottom-3rem">
						{berichteUiiStore.selectedAgt ? (
							<div>{<AkumaPrimeAgtStackedHistory schema={schema} data={data} />}</div>
						) : (
							<>{agtAkumaPrimeUiStore.showGraphs && <AkumaPrimeHorizontalBarChart data={data} primeType={AkumaPrimeType.einordnung} schema={schema} />}</>
						)}

						<AkumaPrimeListe
							schema={schema}
							quoteProps={'einordnungsQuote'}
							totalProps={'einordnungTotal'}
							data={data}
							primeType={AkumaPrimeType.einordnung}
							selectedAgt={berichteUiiStore.selectedAgt}
							showKw={!!berichteUiiStore.selectedAgt}
						/>
					</div>
				)}
			</div>
		</>
	);
});
