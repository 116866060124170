import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AgtFitnessVm, FitModel } from 'app/stores/ui/agt.fitness.ui.store';

export const FitnessTrackerListeExport = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const simpleFitCell = (f: FitModel) => {
		return <>{f.ergebnis}</>;
	};

	const tm = useMemo(() => {
		const tm = new TableModel<AgtFitnessVm, number>();
		const cols: ITableColumn<AgtFitnessVm, number>[] = [];

		cols.push({
			label: 'Agentur ID',
			path: 'agt.agtId',
			sortBy: 'agt.agtId',
		});

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
		});

		// cols.push({
		// 	label: 'Email',
		// 	path: 'agt.email',
		// });

		cols.push({
			label: 'VD',
			path: 'agt.vdId',
		});
		cols.push({
			label: 'GS',
			path: 'agt.gsId',
		});

		// GOOGLE
		cols.push({
			label: 'bew',
			path: `bew.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.anzBew);
			},
		});
		cols.push({
			label: 'bewAvg',
			path: `bewAvg.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.bewAvg);
			},
		});
		cols.push({
			label: 'imgCount',
			path: `imgCount.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.imgCount);
			},
		});

		cols.push({
			label: 'qCount',
			path: `qCount.ergebniss`,
			render(row) {
				return simpleFitCell(row.data.qCount);
			},
		});

		/// AMIS.NOW

		/// AKUMA
		cols.push({
			label: 'isAkumaPrimeUser',
			path: `isAkumaPrimeUser`,
		});

		//  --- PLANER ---
		cols.push({
			label: 'planerAnzahlWochenNutzung',
			path: `planerAnzahlWochenNutzung.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.planerAnzahlWochenNutzung);
			},
		});
		cols.push({
			label: 'planerUebertragsungquoteAmisNow',
			path: `planerUebertragsungquoteAmisNow.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.planerUebertragsungquoteAmisNow);
			},
		});

		//  --- PRIME ---
		cols.push({
			label: 'primeEinordnungsquote',
			path: `primeEinordnungsquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.primeEinordnungsquote);
			},
		});

		cols.push({
			label: 'primeAbwahlquote',
			path: `primeAbwahlquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.primeAbwahlquote);
			},
		});
		cols.push({
			label: 'primeTerminierungExtern',
			path: `primeTerminierungExtern.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.primeTerminierungExtern);
			},
		});

		/// Meine Allianz
		cols.push({
			label: 'mazBestandDurchdringungsquote',
			path: `mazBestandDurchdringungsquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.mazBestandDurchdringungsquote);
			},
		});
		cols.push({
			label: 'mazNeuDurchdringungsquote',
			path: `mazNeuDurchdringungsquote.ergebnis`,
			render(row) {
				return simpleFitCell(row.data.mazNeuDurchdringungsquote);
			},
		});

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, []);

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Fitnesstrackerdaten gefunden</div>
			) : (
				<>
					<Table stickyHeader={true} tm={tm} allowCsvExport={true} csvExportName="fitness-tracker-export.csv"></Table>
				</>
			)}
		</>
	);
});
