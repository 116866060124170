import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import { ISelectOpt } from 'app/components/common/Select';
import { formatKw } from 'app/utils';
import { AkumaPlanerKWModel, KvAgenturYearAkumaPlanerStore } from '../kv.agentur.year.akumaplaner.store';
import moment from 'moment';
import colors from 'assets/scss/colors.module.scss';
import { BaseUiStore } from './base.ui.store';

export interface IAkumaPlanerDef {
	label: string;
	sort: number;
	path: string;
	color?: string;
}

// update akuma_planer_temp set art = 'nb' where art = 'Nicht bearbeitet'; -- fka om
// nu - Kunden mit Zuweisung aber nicht übertragen
//     update akuma_planer_temp set art = 'zagt' where art = 'Zuweisung: Agentur';
//     update akuma_planer_temp set art = 'zma' where art = 'Zuweisung: Mitarbeiter';

// berechnet
// ku - Kunden mit Zuweisung aber übertragen
// ku = zagt + zma
// gesamt = km + nb + nu + ku

// für gpwertung 6 mal im Jahr : ku /gesamt >= 0.5

export const AkumaPlanerArtSchema: IAkumaPlanerDef[] = [
	{
		label: 'Gesamt',
		path: 'gesamt',
		sort: 1,
		color: colors.turquoise,
	},
	{
		label: 'Kundenzuweisung und Übertragung >= 50%',
		path: 'gpNoWochen',
		sort: 1,
		color: colors.richTurquoise,
	},
	{
		label: 'Nicht bearbeitet',
		path: 'nb',
		sort: 1,
		color: colors.red,
	},
	{
		label: 'Keine Maßnahme',
		path: 'km',
		sort: 2,
		color: colors.greyLight,
	},
	{
		label: 'Kunden mit Zuweisung, nicht übertragen',
		path: 'nu',
		sort: 2,
		color: colors.blue,
	},
	{
		label: 'Kunden mit Zuweisung und übertragen',
		path: 'zu',
		sort: 1,
		color: colors.green,
	},
	{
		label: 'Zuweisung Agentur',
		path: 'zagt',
		sort: 3,
		color: colors.darkYellow,
	},
	{
		label: 'Zuweisung Mitarbeiter',
		path: 'zma',
		sort: 4,
		color: colors.purple,
	},
];

export class AgtAkumaPlanerVM {
	agt: AgenturModel;
	kwItems: AkumaPlanerKWModel[] = [];

	constructor(agt: AgenturModel, kvs: AkumaPlanerKWModel[]) {
		this.agt = agt;
		this.kwItems = kvs;
	}

	@computed
	get gesamt() {
		return _.sumBy(this.kwItems, 'gesamt');
	}

	@computed
	get gpWochen() {
		return _.sumBy(this.kwItems, 'gpWochen');
	}
	@computed
	get gpNoWochen() {
		// not in use?
		return _.sumBy(this.kwItems, 'gpNoWochen');
	}

	@computed
	get zu() {
		return _.sumBy(this.kwItems, 'zu');
	}

	@computed
	get km() {
		return _.sumBy(this.kwItems, 'km');
	}
	@computed
	get nb() {
		return _.sumBy(this.kwItems, 'nb');
	}
	@computed
	get nu() {
		return _.sumBy(this.kwItems, 'nu');
	}
	@computed
	get zagt() {
		return _.sumBy(this.kwItems, 'zagt');
	}
	@computed
	get zma() {
		return _.sumBy(this.kwItems, 'zma');
	}

	@computed
	get uebertragungsQuote() {
		// 	Übertragungsquote:
		// Nucht bearbeitet einblenden,
		// Kunden mit zuweisung UND übertragen / gesamt. Will always be quite low
		const q = this.gesamt > 0 ? (this.zu / this.gesamt) * 100 : 0;
		return Math.round(q * 100) / 100;
	}
}

export class AgtAkumaPlanerUiStore extends BaseUiStore<AkumaPlanerKWModel> {
	name = 'AgtAkumaPlanerUiStore';
	agenturStore: AgenturStore;
	kvAgenturYearAkumaPlanerStore: KvAgenturYearAkumaPlanerStore;

	constructor(agenturStore: AgenturStore, kvAgenturYearAkumaPlanerStore: KvAgenturYearAkumaPlanerStore) {
		super();
		makeObservable(this);
		this.kvAgenturYearAkumaPlanerStore = kvAgenturYearAkumaPlanerStore;
		this.agenturStore = agenturStore;
	}
	onLogout() {
		this.kvAgenturYearAkumaPlanerStore.onLogout();
		this.agenturStore.onLogout();
	}

	filterByAgtId(agtId: number) {
		return this.allVmItems.filter((i) => i.agt.agtId === agtId);
	}

	filterByAgtIdandKw(agtId: number) {
		let items = this.currentKWItems.filter((i) => i.agt.agtId === agtId);
		return items;
	}

	@observable
	kws: number[] = [];

	@computed
	get kwsOpts(): ISelectOpt[] {
		return this.kws.map((kw) => {
			return {
				value: kw,
				label: formatKw(kw),
			};
		});
	}

	@computed
	get minKw(): number {
		if (this.minKwCurrent) {
			return this.minKwCurrent;
		}

		return 0;
	}

	@computed
	get maxKw(): number {
		if (this.maxKwCurrent) {
			return this.maxKwCurrent;
		}

		return 0;
	}

	@computed
	get lastWeek() {
		return this.kws[this.kws.length - 1];
	}

	@observable
	minKwCurrent?: number;

	@observable
	maxKwCurrent?: number;

	@observable
	allVmItems: AgtAkumaPlanerVM[] = [];

	// @observable
	// items: AkumaPlanerKWModel[] = [];

	@computed
	get currentKWItems() {
		const kvVmItems: AgtAkumaPlanerVM[] = [];
		this.allVmItems.forEach((vm) => {
			const f = vm.kwItems.filter((i) => {
				if (this.minKw && i.bm >= this.minKw) {
					if (this.maxKw && i.bm <= this.maxKw) {
						return true;
					}
				}
				return false;
			});
			const r = new AgtAkumaPlanerVM(vm.agt, f);
			kvVmItems.push(r);
		});

		return kvVmItems;
	}

	@action
	async _baseLoad() {
		const promises = await Promise.all([this.kvAgenturYearAkumaPlanerStore.findAll(), this.agenturStore.findAll()]);
		const data = promises[0];
		let agts = promises[1].slice();

		runInAction(() => {
			this.kws = _.uniq(_.map(data, 'bm')).sort();
			this.minKwCurrent = _.min(this.kws);
			this.maxKwCurrent = _.max(this.kws);
		});

		const groupedByAgt = _.chain(data)
			.groupBy('agtId')
			.map((value, key) => {
				return value;
			})
			.value();

		runInAction(() => {
			const res: AgtAkumaPlanerVM[] = [];
			groupedByAgt.forEach((items) => {
				let agt;
				[agt, agts] = this.findAndSplice(agts, (a) => a.agtId === items[0].agtId);
				if (!agt) {
					// console.warn('could not find agt with id' + items[0].agtId);
					return;
				}
				res.push(new AgtAkumaPlanerVM(agt, items));
			});
			this.allVmItems = res;
			this.items = data;
			const max = _.maxBy(data, 'bm');
			if (max) {
				this.datenStand = max.bm;
			}
		});
	}

	countPassedItemsForCurrentYear(agtId: number) {
		const currYear = moment().year();
		const currYearItems = this.items.filter((i) => i.agtId === agtId && i.year === currYear && i.gpWochen >= 1);
		return currYearItems.length;
	}
}
