import { observer } from 'mobx-react';
import { formatDateLong } from 'app/utils';
import { Button } from '../common/Button';
import { useStore } from 'app/context';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TextEditorField } from '../form/TextEditorField';
import { Icon } from '../common/Icon';
import { ConfirmModal } from '../common/ConfirmModal';
import { runInAction } from 'mobx';
import { NotizVM } from 'app/stores/ui/notizen.ui.store';
import { useEffect, useState } from 'react';
import { FileUpload } from '../common/FileUpload';
import { BnrUploadModel } from 'app/stores/bnr.upload.store';
import { FileTypeIcon } from '../common/FileTypeIcon';
import { FileSize } from '../common/FileSize';
import { notify } from '../common/notify';

interface INotiz {
	doc: NotizVM;
}

const NotizActions = observer((props: INotiz) => {
	const { uiStore, notizenUiStore } = useStore();

	const doc = props.doc;
	const confirmDeleteModalId = uiStore.modalIds.confirmDeleteDocument + doc.id;
	const showConfirmDlg = () => {
		runInAction(() => {
			uiStore.showModal(confirmDeleteModalId);
		});
	};
	const onDelete = async () => {
		await notizenUiStore.deleteById(doc.id);
		notizenUiStore.setCurrentModalDoc();
	};

	return (
		<div className="document-actions">
			<>
				<Button className="button is-icon is-small" onClick={showConfirmDlg} data-tooltip="Löschen" data-tooltip-position="bottom">
					<Icon iconClass="trash" />
				</Button>
				<ConfirmModal onConfirm={onDelete} onCancel={() => { }} modalId={confirmDeleteModalId}>
					Sind Sie sicher, dass Sie diese Notiz löschen wollen? Dies kann NICHT rückgängig gemacht werden.
				</ConfirmModal>
			</>
			<Button className={`button is-icon is-small is-readonly ${doc.doc.editable ? 'is-grey' : ''}`} data-tooltip={doc.doc.editable ? 'Bearbeitbar' : 'Schreibgeschützt'} data-tooltip-position="bottom">
				{doc.doc.editable ? <Icon iconClass="unlock-alt" /> : <Icon iconClass="lock" />}
			</Button>
		</div>
	);
});
const NotizChanges = observer((props: INotiz) => {
	const doc = props.doc.doc;
	return (
		<div className="document-changes">
			<span>
				Erstellt: <strong>{formatDateLong(doc.created)}</strong>
			</span>
			<span>
				Zuletzt geändert: <strong>{formatDateLong(doc.modified)}</strong>
			</span>
		</div>
	);
});

interface INotizEditState extends INotiz {
	dirty: boolean;
	onClose: () => void;
}

const NotizEditState = observer((props: INotizEditState) => {
	const { notizenUiStore } = useStore();
	const { resetForm } = useFormikContext();
	const [isFirst, setIsFirst] = useState<boolean>(true);
	const [isLast, setIsLast] = useState<boolean>(true);

	useEffect(() => {
		const id = props.doc.doc.id;
		const firstDoc = notizenUiStore.items[0];
		const lastDoc = notizenUiStore.items[notizenUiStore.items.length - 1];

		if (firstDoc) {
			setIsFirst(firstDoc.id === id);
		}
		if (lastDoc) {
			setIsLast(lastDoc.id === id);
		}
	}, [notizenUiStore.items, props.doc.doc.id]);

	const onCancel = () => {
		resetForm();
		props.onClose();
	};

	const onNext = () => {
		notizenUiStore.setNextModalDoc();
	};
	const onPrev = () => {
		notizenUiStore.setPreviousModalDoc();
	};

	return (
		<div className="modal-footer document-edit-state grid is-col-half">
			<div className="is-left">
				<>
					<Button className="button is-primary is-inverted" onClick={onPrev} disabled={isFirst || props.dirty}>
						<Icon iconClass="chevron-left" />
					</Button>
					<Button className="button is-primary is-inverted" onClick={onNext} disabled={isLast || props.dirty}>
						<Icon iconClass="chevron-right" />
					</Button>
				</>
			</div>
			<div className="is-right">
				<>
					<Button className="button is-primary" type="submit">
						Speichern
					</Button>
					<Button className="button" onClick={onCancel}>
						Abbrechen
					</Button>
				</>
			</div>
		</div>
	);
});

interface INotizViewer {
	doc: NotizVM;
	onClose: () => void;
}

const documentFormSchema = Yup.object({
	text: Yup.string().default(''),
	title: Yup.string().default('Notiz'),
}).required();

type DocumentForm = Yup.InferType<typeof documentFormSchema>;

export const NotizViewer = observer((props: INotizViewer) => {
	const { notizenUiStore } = useStore();
	const doc = props.doc.doc;

	const title = doc.title;
	const text = doc.content.text;

	const handleSubmit = async (values: DocumentForm) => {
		doc.content.text = values.text;
		doc.title = values.title;
		await notizenUiStore.save(props.doc);
		runInAction(() => {
			props.doc.isNew = false;
		});
		notizenUiStore.setCurrentModalDoc();
	};

	return (
		<div className={`is-user-notiz`}>
			<Formik
				initialValues={{
					title,
					text,
					agtId: doc.agtId ? doc.agtId : 0,
					planYear: doc.planYear,
				}}
				validationSchema={documentFormSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, dirty }) => {
					return (
						<>
							<div className="debug">
								{doc.id} - {doc.category}
							</div>

							<Form className="document-form">
								<NotizActions {...props} />
								<NotizChanges {...props} />
								<div className="document-content">
									<TextEditorField name="text" />{' '}
								</div>
								<NotizenUploads {...props} />
								<NotizEditState {...props} dirty={dirty} onClose={props.onClose} />
							</Form>
						</>
					);
				}}
			</Formik>
		</div>
	);
});

interface INotizenUploads {
	doc: NotizVM;
}

const NotizenUploads = observer((props: INotizenUploads) => {
	const { notizenUiStore } = useStore();
	const [uploads, setUploads] = useState(props.doc.uploads);

	const onUploadDone = async () => {
		await notizenUiStore.refresh();
		const doc = await notizenUiStore.findById(props.doc.id);
		if (doc) {
			setUploads(doc.uploads);
			runInAction(() => {
				props.doc.uploads = doc.uploads;
			});
		}
	};

	return (
		<div className="document-uploads">
			<div className="uploads-title">
				<Icon iconClass="paperclip" />
				<span>Anhänge</span>
				<span className="tag is-marginless is-small">{uploads.length}</span>
			</div>
			{props.doc.doc.editable && (
				<FileUpload
					maxSize={20000000}
					// maxFiles={1}
					multiple={false}
					dropZoneActiveText={'Datei hier loslassen'}
					dropZoneText={'Datei hinzufügen'}
					uploadingText={'Upload läuft'}
					dokId={props.doc.id}
					type={'user_notiz'}
					icon="plus-circle"
					inline={true}
					onUploadDone={onUploadDone}
				/>
			)}

			{uploads.length > 0 && (
				<div className="upload-items">
					{uploads.map((u) => (
						<DocumentUpload key={u.id} document={props.doc} upload={u} />
					))}
				</div>
			)}
		</div>
	);
});

interface IDocumentUpload {
	document: NotizVM;
	upload: BnrUploadModel;
}
const DocumentUpload = observer((props: IDocumentUpload) => {
	const { notizenUiStore, uiStore } = useStore();
	const upload = props.upload;
	const confirmDeleteModalId = uiStore.modalIds.confirmDeleteUpload + upload.id;
	const onDownload = async () => {
		await notizenUiStore.bnrUploadStore.downloadFile(upload.id, upload.title, upload.type);
	};

	const showConfirmDelete = () => {
		uiStore.showModal(confirmDeleteModalId);
	};
	const onDelete = async () => {
		await notizenUiStore.deleteUpload(props.document, upload);
		notify('Dokument wurde gelöscht', '', 'info');
	};

	return (
		<>
			<div className="document-upload">
				<Button className="button is-blank download-button " onClick={onDownload}>
					<FileTypeIcon mime={upload.fileType} />
					<div className="file-name">{upload.title}</div>
					<FileSize fileSize={upload.fileSize} />
				</Button>
				<Button className="button is-icon is-small delete-button" onClick={showConfirmDelete}>
					<Icon iconClass="trash" />
				</Button>
			</div>
			<ConfirmModal onConfirm={onDelete} onCancel={() => { }} modalId={confirmDeleteModalId}>
				Sind Sie sicher, dass Sie diesen Upload löschen wollen? Dies kann nicht rückgängig gemacht werden.
			</ConfirmModal>
		</>
	);
});
