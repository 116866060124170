import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { formatDate } from 'app/utils';
import { Table } from 'app/components/table/table';
import { Button } from 'app/components/common/Button';
import { runInAction } from 'mobx';
import { AgenturPersonEditModal } from './AgenturPersonalEdit';
import { AgenturPersonViewModal } from './AgenturPersonalView';
import { Icon } from 'app/components/common/Icon';
import { PersonalVM } from 'app/models/personal/personal.vm';

export const AgenturPersonalListe = observer(() => {
	const { personalUiStore, agenturUiStore, uiStore, personalCustomStore } = useStore();
	const [selectedId, setSelectId] = useState<string | undefined>();
	const agt = agenturUiStore.current;

	const onEdit = (id: string) => {
		runInAction(() => {
			setSelectId(id);
			uiStore.hideModal(uiStore.modalIds.mitarbeiterView);
			uiStore.showModal(uiStore.modalIds.mitarbeiterEdit);
		});
	};

	const onDelete = (id: string) => {
		personalCustomStore.delete(id).then(() => {
			runInAction(async () => {
				uiStore.hideModal(uiStore.modalIds.mitarbeiterView);
				uiStore.hideModal(uiStore.modalIds.mitarbeiterEdit);
				setSelectId(undefined);
				loadData();
			});
		});
	};
	const tm = useMemo(() => {
		const onView = (id: string) => {
			runInAction(() => {
				setSelectId(id);
				uiStore.showModal(uiStore.modalIds.mitarbeiterView);
			});
		};
		const tm = new TableModel<PersonalVM, number>();
		const cols = [
			{
				label: '',
				path: 'stellenBezeichnung',
			},
			{
				label: 'Name',
				path: 'nachname',
				render: (row: TableRow<PersonalVM>) => {
					return <>{row.data.fullName}</>;
				},
			},
			{
				label: 'Geburtsdatum',
				path: 'gebDat',
				sortBy: 'gebDatMonthDay',
				format: formatDate,
				render: (row: TableRow<PersonalVM>) => {
					return <>{row.data.canShowGeburtstag && <>{formatDate(row.data.gebDat)}</>}</>;
				},
			},
			{
				label: '',
				path: 'hasUpcomingBirthday',
				render: (row: TableRow<PersonalVM>) => {
					return <>{row.data.hasUpcomingBirthday && <Icon iconClass="birthday-cake" />}</>;
				},
			},
			{
				label: 'Gründung / Eintritt',
				path: 'beitrittDat',
				sortBy: 'beitrittDatMonthDay',
				format: formatDate,
			},
			{
				label: 'Jubiläum',
				path: 'anniversaryDate',
				sortBy: 'beitrittDatMonthDay', // same sort as above
				format: formatDate,
			},
			{
				label: '',
				path: 'hasUpcomingAnniversary',
				render: (row: TableRow<PersonalVM>) => {
					return (
						<>
							{row.data.hasUpcomingAnniversary && (
								<>
									<Icon iconClass="award" />
								</>
							)}
						</>
					);
				},
			},
		];
		tm.onRowClick = (row) => {
			onView(row.data.id);
		};
		tm.setCols(cols);
		tm.sortBy = 'beitrittDatMonthDay';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, [uiStore]);

	const loadData = useCallback(() => {
		personalUiStore.reloadAll().then((res) => {
			if (agt) {
				res = res.filter((p) => p.agtId === agt.agtId);
				tm.setRowData(res);
			} else {
				tm.setRowData([]);
			}
		});
		return personalUiStore.items.length;
	}, [tm, agt, personalUiStore]);

	useEffect(() => {
		loadData();
	}, [loadData]);
	return (
		<>
			<AgenturPersonEditModal id={selectedId} onChange={() => loadData()} onClose={() => setSelectId(undefined)} />
			<AgenturPersonViewModal id={selectedId} onClose={() => setSelectId(undefined)} onEdit={onEdit} onDelete={onDelete} />

			<Table modifier="is-compact" tm={tm}></Table>
		</>
	);
});

export const AgenturPersonalWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore, uiStore } = useStore();
	const modalId = uiStore.modalIds.mitarbeiterEdit;

	const agt = agenturUiStore.current;

	if (!agt) {
		return <></>;
	}
	const onModalOpen = () => {
		runInAction(() => {
			uiStore.showModal(modalId);
		});
	};

	return (
		<Widget title={'Personal'} modifier="is-paddingless" widgetId={props.widgetKey}>
			<AgenturPersonalListe />

			<div className="has-text-centered">
				<Button type="button" className="button is-text is-padded is-small" onClick={onModalOpen}>
					Mitarbeiter Hinzufügen
				</Button>
			</div>
		</Widget>
	);
});
