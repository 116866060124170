import { useStore } from 'app/context';
import { AupFile } from 'app/stores/admin.imports.store';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import routes from 'routes';
import { notify } from '../common/notify';
import { useHistory } from 'react-router';
import { formatDateLong, humanizeFileSize } from 'app/utils';
import { Button } from '../common/Button';
import { observer } from 'mobx-react';
import { ITableColumn, TableModel, TableRow } from '../table/table.model';
import { Table } from '../table/table';
import { useEffect } from 'react';

interface IAdminImportFiles {
	files: AupFile[];
	job: ImportJobVM;
}
export const AdminImportFiles = observer((props: IAdminImportFiles) => {
	const { adminImportsUiStore } = useStore();
	const history = useHistory();

	const startImportJob = (fileName: string) => {
		if (!window.confirm(`Soll der Import mit der Datei ${fileName} gestartet werden?`)) {
			return;
		}
		if (!props.job.job.meta) {
			props.job.job.meta = {};
		}
		props.job.job.meta.fileName = fileName;
		notify('Job wird gestartet', '', 'info');
		adminImportsUiStore.triggerJob(props.job.job).then((res: any) => {
			history.push(routes.ADMINIMPORTS.getPath('job', res.id));
		});
	};

	const handleDelete = (fileName: string) => {
		if (!window.confirm(`Soll die Datei ${fileName} wirklich gelöscht werden?`)) {
			return;
		}

		notify('Datei wird gelöscht', '', 'info');
		fileName = props.job.job.dataType + '/' + fileName;
		adminImportsUiStore.adminImportsStore.deleteUploadFile(fileName).then(() => {
			window.location.reload();
		});
	};

	const tm = new TableModel<AupFile, number>();
	let cols: ITableColumn<AupFile, number>[] = [
		{
			label: 'Name',
			path: 'fileName',
		},
		{
			label: 'Datum',
			path: 'fileDate',
			format: (val) => formatDateLong(val),
		},
		{
			label: 'Größe',
			path: 'fileSize',
			format: (val) => humanizeFileSize(val),
		},
		{
			label: 'Import',
			path: 'fehler',
			render: (row: TableRow<AupFile>) =>
				<Button type="button" className={'button is-primary'} onClick={() => startImportJob(row.data.fileName)}>
					Importieren
				</Button>,
		},

		{
			label: 'Löschen',
			path: 'Delete',
			render: (row: TableRow<AupFile>) =>
				<Button type="button" className={'button is-danger'} onClick={() => handleDelete(row.data.fileName)}>
					Löschen
				</Button>,
		},
	];
	tm.setCols(cols);
	tm.sortBy = 'fileDate';
	tm.sortAsc = false;
	tm.idProperty = 'id';
	tm.idType = 'string';

	useEffect(() => {
		tm.setRowData(props.files);

	}, [props.files])

	return (
		<div className="pad-top-1rem border-top">
			<strong>Hochgeladene Dateien: </strong>
			<br />
			{props.files.length === 0 && <>Keine Dateien hochgeladen </>}
			<Table stickyHeader={true} tm={tm}></Table>
			{/* 
			<table className={'table'}>
				<thead>
					<tr>
						<th>Name</th>
						<th>Größe</th>
						<th>Datum</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{props.files.map((f) => (
						<tr key={f.fileName}>
							<td>{f.fileName}</td>
							<td>{humanizeFileSize(f.fileSize)}</td>
							<td>{formatDateLong(f.fileDate)}</td>
							<th>
								<Button type="button" className={'button is-primary'} onClick={() => handleSubmit(f.fileName)}>
									Importieren
								</Button>
							</th>
						</tr>
					))}
				</tbody>
			</table> */}
		</div>
	);
});
