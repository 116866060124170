import { Icon } from 'app/components/common/Icon';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtRingPos } from 'app/stores/ui/agt.ring.ui.store';
import { formatNum } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { RingDisclaimer } from './RingDisclaimer';

export interface IGPYear {
	year: number;
}

export const AgtRingPositionen = observer((props: IGPYear) => {
	const { agtRingUiStore, berichteUiiStore, agenturListUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<AgtRingPos, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'Gold',
				path: 'gold.ziel',
				render: (row: TableRow<AgtRingPos>) => {
					return (
						<>
							<span className={`medal is-small ${row.data.hasGold ? 'is-gold' : ''}`}>
								<Icon iconClass={'trophy'} />
							</span>{' '}
							<span className={row.data.hasGold ? 'is-bold' : ''}>{formatNum(row.data.gold?.ziel || 0)}</span>
						</>
					);
				},
			},
			{
				label: 'Silber',
				path: 'silber.ziel',
				render: (row: TableRow<AgtRingPos>) => {
					return (
						<>
							<span className={`medal is-small ${row.data.hasSilber ? 'is-silber' : ''}`}>
								<Icon iconClass={'trophy'} />
							</span>{' '}
							<span className={row.data.hasSilber ? 'is-bold' : ''}>{formatNum(row.data.silber?.ziel || 0)}</span>
						</>
					);
				},
			},
			{
				label: 'Bronze',
				path: 'bronze.ziel',
				render: (row: TableRow<AgtRingPos>) => {
					return (
						<>
							<span className={`medal is-small ${row.data.hasBronze ? 'is-bronze' : ''}`}>
								<Icon iconClass={'trophy'} />
							</span>{' '}
							<span className={row.data.hasBronze ? 'is-bold' : ''}>{formatNum(row.data.bronze?.ziel || 0)}</span>
						</>
					);
				},
			},
			{
				label: 'Ergebnis',
				path: 'ergebnis',
				format: formatNum,
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'retirementDate';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		const p = berichteUiiStore.menu.currentPath;
		if (p) {
			const goldId = parseInt(p);
			agtRingUiStore.findByGoldPosId(goldId, props.year).then((res) => {
				res = agenturListUiStore.applyFilterOnRing(res);
				if (berichteUiiStore.selectedAgt) {
					const agtId = berichteUiiStore.selectedAgt.agtId;
					res = res.filter((d) => d.agt.agtId === agtId);
				}

				tm.setRowData(res);
			});
		}
	}, [tm, agtRingUiStore, agtRingUiStore.cache, agenturListUiStore.items, agtRingUiStore.cache, berichteUiiStore.selectedAgt, berichteUiiStore.menu.currentPath, props.year, agenturListUiStore]);
	return (
		<>
			<div className="table-container">
				<RingDisclaimer />
				<Table tm={tm} stickyHeader={true}></Table>
			</div>
		</>
	);
});
