import { Steart } from 'app/models/core/steart.model';
import _ from 'lodash';
import { computed, observable } from 'mobx';
import { AdminAgenturStore } from '../agentur.store';
import { BnrAgenturStore } from '../bnr.agentur.store';
import { BnrModel, BnrStore } from '../bnr.store';

export class GsVbVM {
	bnr: BnrModel;
	constructor(bnr: BnrModel) {
		this.bnr = bnr;
	}
	agtIds: number[] = [];

	team: TeamMemberVM[] = [];

	@computed
	get vblAgtIds() {
		let vblAgtIds: number[] = [];
		this.team.forEach((t) => {
			if (t.isVbl) {
				if (this.bnr.bnrId === 7024346) {
					console.log(t.bnr.bnrId, t.bnr.name, t.bnr.bnrSteart);
				}
				vblAgtIds = vblAgtIds.concat(t.agtIds);
			}
		});
		return _.uniq(vblAgtIds);
	}

	@computed
	get vbUnbetreut() {
		return this.agtIds.length - this.vblAgtIds.length;
	}

	@computed
	get vbUnbetreuteAgtIds() {
		const res: number[] = [];
		this.agtIds.forEach((a) => {
			if (!this.vblAgtIds.includes(a)) {
				res.push(a);
			}
		});
		return res;
	}
}

export class TeamMemberVM {
	bnr: BnrModel;
	constructor(bnr: BnrModel) {
		this.bnr = bnr;
	}
	agtIds: number[] = [];

	@computed
	get isVbl() {
		return this.bnr.steart.id === 934;
	}
}

class CacheItem {
	constructor() {
		this.loadingPromise = new Promise((resolve) => {
			this._resolver = resolve;
		});
	}
	loaded: boolean = false;
	loading: boolean = false;
	markAsLoaded = () => {
		if (this._resolver) {
			this._resolver();
			this.loaded = true;
			this.loading = false;
		}
	};
	_resolver: any;
	loadingPromise: Promise<any>;
}

export class GsvbUiStore {
	bnrStore: BnrStore;
	bnrAgenturStore: BnrAgenturStore;
	adminAgenturStore: AdminAgenturStore;
	constructor(bnrStore: BnrStore, bnrAgenturStore: BnrAgenturStore, adminAgenturStore: AdminAgenturStore) {
		// makeObservable(this);
		this.bnrStore = bnrStore;
		this.bnrAgenturStore = bnrAgenturStore;
		this.adminAgenturStore = adminAgenturStore;
	}

	@observable
	cache: CacheItem = new CacheItem();
	items: GsVbVM[] = [];

	async load() {
		debugger;
		if (this.cache.loaded) {
			return this.items;
		}
		if (this.cache.loading) {
			await this.cache.loadingPromise;
			return this.items;
		}
		this.cache.loading = true;

		if (this.items.length > 0) {
			return this.items;
		}
		const l = await Promise.all([this.bnrStore.findAll(), this.bnrAgenturStore.findAll(), this.adminAgenturStore.findAll()]);
		let bnrs = l[0];
		let bnrAgts = l[1];
		const agts = l[2];
		const activeAgtIds = agts.filter((agt) => agt.vtnrLebend).map((a) => a.agtId);

		// keep only active bnrAgts
		bnrAgts = bnrAgts.filter((b) => activeAgtIds.includes(b.agtId));

		// keep only bnrs of stearts we support
		bnrs = bnrs.filter((bnr) => bnr.steart.steart !== Steart.XXX);

		const res: GsVbVM[] = [];

		for (const bnr of bnrs) {
			if (bnr.bnrSteart === 900) {
				const vm = new GsVbVM(bnr);
				res.push(vm);

				// collect gs agts
				let agtIds = bnrAgts.filter((b) => b.bnrId === bnr.bnrId).map((ba) => ba.agtId);
				vm.agtIds = _.uniq(agtIds);

				// collect gs team bnrIds
				let teamBnrIds = bnrAgts.filter((ba) => agtIds.includes(ba.agtId)).map((ba) => ba.bnrId);
				teamBnrIds = _.uniq(teamBnrIds);
				let teamBnrs = bnrs.filter((b) => teamBnrIds.includes(b.bnrId));

				vm.team = teamBnrs.map((memBnr) => {
					const mem = new TeamMemberVM(memBnr);
					let memAgtIds = bnrAgts.filter((b) => b.bnrId === memBnr.bnrId).map((ba) => ba.agtId);
					mem.agtIds = _.uniq(memAgtIds);

					return mem;
				});
			}
		}

		this.items = res;
		this.cache.markAsLoaded();
		return res;
	}
}
