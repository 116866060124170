import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { AgtPosAktStore, AgtPosBjStore, AgtPosMsStore } from '../agt.pos.stores';
import { SessionStore } from '../session.store';
import _ from 'lodash';
import { AgtBestandHistoryVM } from 'app/models/berichte/agt.bestand.history.vm';
import { AgtBestandUndBewertungVM } from 'app/models/berichte/agt.bestand.und.bewertung.vm';
import { AgtBestandPosModel, IAgtBestandPosDefinition } from 'app/models/berichte/agt.bestand.pos.model';
import { BaseUiStore } from './base.ui.store';
import { action } from 'mobx';

/*

{id: 34, title: 'Sach gesamt (ohne AGCS/mit ESA)', zuwId: 33},
{id: 41, title: 'Privat ohne Kraft', zuwId: 38},
{id: 42, title: 'Firmen o. Kraft/mit ESA', zuwId: 39},
{id: 43, title: 'Kraft', zuwId: 40}
*/

export const BESTANDPOS_ID = 34;
export const GESAMTBEWERTUNGPOS_ID = 32;
var posBjIds: IAgtBestandPosDefinition[] = [
	{ posId: GESAMTBEWERTUNGPOS_ID, title: 'Gesamtbewertung' },
	{ posId: BESTANDPOS_ID, zuwachsPosId: 33, title: 'Sach gesamt (ohne AGCS/mit ESA)' },
	{ posId: 41, zuwachsPosId: 38, title: 'Privat ohne Kraft' },
	{ posId: 42, zuwachsPosId: 39, title: 'Firmen ohne Kraft/mit ESA' },
	{ posId: 43, zuwachsPosId: 40, title: 'Kraft' },
];

const bestandPosIds = [32, 34, 33, 41, 38, 42, 39, 43, 40];

export class AgtBestandUiStore extends BaseUiStore<AgtBestandUndBewertungVM> {
	name = 'AgtBestandUiStore';
	session: SessionStore;
	agenturStore: AgenturStore;
	agtPosBjStore: AgtPosBjStore;
	agtPosMsStore: AgtPosMsStore;
	agtPosAktStore: AgtPosAktStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, agtPosBjStore: AgtPosBjStore, agtPosMsStore: AgtPosMsStore, agtPosAktStore: AgtPosAktStore) {
		super();
		this.session = session;
		this.agenturStore = agenturStore;
		this.agtPosBjStore = agtPosBjStore;
		this.agtPosMsStore = agtPosMsStore;
		this.agtPosAktStore = agtPosAktStore;
		this.agtPosBjStore.registerPosIds(bestandPosIds);
		this.agtPosMsStore.registerPosIds(bestandPosIds);
		this.agtPosAktStore.registerPosIds(bestandPosIds);
	}

	onLogout() {
		this.agenturStore.onLogout();
		this.agtPosBjStore.onLogout();
		this.agtPosMsStore.onLogout();
		this.agtPosMsStore.onLogout();
		this.agtPosAktStore.onLogout();
	}

	@action
	async _baseLoad() {
		let vjDezS = moment().year() - 1 + '12';
		const vjDez = parseInt(vjDezS);
		const res = await Promise.all([this.agenturStore.findAll(), this.agtPosAktStore.findAll(), this.agtPosBjStore.findAll()]);
		const agts = res[0].sort((a, b) => a.agtId - b.agtId);;
		let posAkt = res[1];
		let posBj = res[2];

		console.log(bestandPosIds);
		// const t = new AgtBestandUndBewertungVM({});
		// a lot faster for users many agts
		posAkt = posAkt.filter((p) => bestandPosIds.includes(p.posId)).slice().sort((a, b) => a.agtId - b.agtId);
		posBj = posBj.filter((p) => bestandPosIds.includes(p.posId) && p.bm === vjDez).slice().sort((a, b) => a.agtId - b.agtId);

		const items: AgtBestandUndBewertungVM[] = [];

		console.log(agts[0].agtId, posAkt[0].agtId, posBj[0].agtId);
		for (const agt of agts) {
			const item = new AgtBestandUndBewertungVM(agt);
			items.push(item);

			// const bjVjDezItems = posBj.filter((bj) => bj.agtId === agt.agtId && bj.bm === vjDez);
			// const vjItems = posAkt.filter((akt) => akt.agtId === agt.agtId && akt.bjvj === 'VJ');
			// const aktItems = posAkt.filter((akt) => akt.agtId === agt.agtId && akt.bjvj === 'BJ');
			let bjVjDezItems, vjItems, aktItems;
			[bjVjDezItems, posBj] = this.filterAndSplice(posBj, (bj) => bj.agtId === agt.agtId && bj.bm === vjDez);
			[vjItems, posAkt] = this.filterAndSplice(posAkt, (akt) => akt.agtId === agt.agtId && akt.bjvj === 'VJ');
			[aktItems, posAkt] = this.filterAndSplice(posAkt, (akt) => akt.agtId === agt.agtId && akt.bjvj === 'BJ');

			item.bestand.setFromList(aktItems);
			item.bestandVJ.setFromList(bjVjDezItems);
			item.bewertung.setFromList(aktItems);
			item.bewertungVJ.setFromList(vjItems);
		}
		this.items = items;
	}


	// refac these into other separtes stores
	async find5YearHistoryByAgtId(agtId: number) {
		const items = await this.find5YearHistory();
		return items.filter((p) => p.agtId === agtId);
	}

	async find5YearHistory() {
		const res = await Promise.all([this.agenturStore.findAll(), this.agtPosBjStore.findAll()]);
		const agts = res[0];
		let posBj = res[1];
		const items: AgtBestandPosModel[] = [];

		posBj = posBj.filter((x) => _.includes(bestandPosIds, x.posId));

		for (const agt of agts) {
			const agtPos = _.sortBy(
				posBj.filter((p) => p.agtId === agt.agtId),
				(p) => p.bm,
			);
			posBjIds.forEach((posDef) => {
				let poses = agtPos.filter((agtPos) => agtPos.posId === posDef.posId);
				poses = _.sortBy(poses, 'bm').reverse();
				const zuwachsPoses = agtPos.filter((agtPos) => agtPos.posId === posDef.zuwachsPosId);
				poses.forEach((pos, i) => {
					const bm = moment(pos.bm + '01');
					const zuwachs = zuwachsPoses.find((i) => i.posId === posDef.zuwachsPosId && bm.isSame(moment(i.bm) + '01', 'd'));
					const item = new AgtBestandPosModel({
						agt,
						posId: posDef.posId,
						zuwachsPosId: posDef.zuwachsPosId,
						title: posDef.title,
						bm: bm.toDate(),
					});
					const x = [pos];
					if (zuwachs) {
						x.push(zuwachs);
					}
					item.setFromList(x);
					//const item = new AgtBestandPos5YearVM2(agt, bm.toDate(), posDef, pos, zuwachs);
					if (i === 0) {
						item.isFirst = true;
					}
					items.push(item);
				});
			});
			// }
		}
		return items;
	}

	async find1YearHistoryByAgtId(agtId: number) {
		const items = await this.find1YearHistory();
		return items.filter((p) => p.agtId === agtId);
	}

	async find1YearHistory() {
		const res = await Promise.all([this.agenturStore.findAll(), this.agtPosMsStore.findAll()]);
		const agts = res[0];
		let posMs = res[1];
		const items: AgtBestandHistoryVM[] = [];
		posMs = posMs.filter((x) => _.includes(bestandPosIds, x.posId));
		for (const agt of agts) {
			const agtPosMs = _.sortBy(
				posMs.filter((p) => p.agtId === agt.agtId),
				(p) => p.bm,
			);
			const byBm = _.chain(agtPosMs)
				.groupBy('bm')
				.map((value, key) => {
					return value;
				})
				.value();
			byBm.forEach((p) => {
				// const bestandPosId = 34;
				// const bewertungPosId = 32;
				// const bestandsZuwachsSachPosId = 33;
				const bm = p[0].bm;
				const item = new AgtBestandHistoryVM(agt, bm);
				item.bestand.setFromList(p);
				item.bewertung.setFromList(p);
				items.push(item);
			});
		}

		const byAgt = _.chain(items)
			.groupBy('agtId')
			.map((value, key) => {
				return value;
			})
			.value();
		byAgt.forEach((agtItems) => {
			// production aus GESAMTBEWERTUNGPOS manuell berechnen da
			// GESAMTBEWERTUNGPOS ist kummulativ!
			agtItems.forEach((p) => {
				let vmP = _.find(items, (i) => {
					return i.agtId === p.agtId && i.year === p.year && i.month === p.month - 1;
				});
				if (!vmP) {
					p.production.val.bt = p.bewertung.val.bt;
					p.production.val.st = p.bewertung.val.st;
				}
				if (vmP) {
					p.production.val.bt = p.bewertung.val.bt - vmP.bewertung.val.bt;
					p.production.val.st = p.bewertung.val.st - vmP.bewertung.val.st;
				}
			});

			// prod monats delta
			agtItems.forEach((p) => {
				let vmP = _.find(items, (i) => {
					return i.agtId === p.agtId && i.year === p.year && i.month === p.month - 1;
				});
				if (!vmP) {
					p.production.zuwachs.bt = 0;
					p.production.zuwachs.st = 0;
				}
				if (vmP) {
					p.production.zuwachs.bt = p.production.val.bt - vmP.production.val.bt;
					p.production.zuwachs.st = p.production.val.st - vmP.production.val.st;
				}
			});
		});

		return items;
	}
}
