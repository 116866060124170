import { PosAktModel } from 'app/models/berichte/pos.akt.model';
import { PosBjModel } from 'app/models/berichte/pos.bj.model';
import { PosMsModel } from 'app/models/berichte/pos.ms.model';
import _ from 'lodash';
import { action, computed, runInAction } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class AgtPosBjStore extends BaseCachedEntityStore<PosBjModel> {
	idProp: string = 'id';
	apiPath = 'api/agt/posbj';
	createInstances(opts: any): PosBjModel {
		return new PosBjModel(opts);
	}

	disableFindForStearts = [123];

	@computed
	get datenStand() {
		if (!this._items || this._items.length === 0) {
			return undefined;
		}
		const max = _.maxBy(this._items, 'bm');
		return max!.bm;
	}

	posIds: number[] = [];
	@action
	registerPosIds(ids: number[]) {
		this.posIds = this.posIds.concat(ids);
		this.apiPath = 'api/agt/posbj/' + this.posIds.join(',');
	}

	async findAll() {
		let items = await this.__findAll();
		runInAction(() => {
			items = items.sort((a, b) => a.agtId - b.agtId);
		});
		return items;
	}
}

export class AgtPosMsStore extends BaseCachedEntityStore<PosMsModel> {
	idProp: string = 'id';
	apiPath = 'api/agt/posms';
	disableFindForStearts = [123];

	createInstances(opts: any): PosMsModel {
		return new PosMsModel(opts);
	}

	posIds: number[] = [];
	@action
	registerPosIds(ids: number[]) {
		this.posIds = this.posIds.concat(ids);
		this.apiPath = 'api/agt/posms/' + this.posIds.join(',');
	}

	async findAll() {
		let items = await this.__findAll();
		runInAction(() => {
			items = items.sort((a, b) => a.agtId - b.agtId);
		});
		return items;
	}
}

export class AgtPosAktStore extends BaseCachedEntityStore<PosAktModel> {
	idProp: string = 'id';
	apiPath = 'api/agt/posakt';
	disableFindForStearts = [123];

	createInstances(opts: any): PosAktModel {
		return new PosAktModel(opts);
	}

	posIds: number[] = [];
	@action
	registerPosIds(ids: number[]) {
		this.posIds = this.posIds.concat(ids);
		this.apiPath = 'api/agt/posakt/' + this.posIds.join(',');
	}

	async findAll() {
		let items = await this.__findAll();
		runInAction(() => {
			items = items.sort((a, b) => a.agtId - b.agtId);
		});
		return items;
	}
}
