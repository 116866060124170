import _ from 'lodash';
import { computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { NewsModel, NewsStore } from '../news.store';
import moment, { Moment } from 'moment';
import { BaseUiStore } from './base.ui.store';

export class NewsVM {
	news: NewsModel;
	constructor(opts: any) {
		makeObservable(this);
		this.news = opts;
	}

	@computed
	get id() {
		return this.news.id;
	}

	isPublishedAfter(date: Date | Moment) {
		return moment(date).isAfter(this.news.publishDate);
	}

	isPublishedBefore(date: Date | Moment) {
		return moment(date).isBefore(this.news.publishDate);
	}

	get textTrunc() {
		if (this.news.text.length < 20) {
			return this.news.text;
		}
		return this.news.text.substring(0, 19);

	}


}

export class NewsUiStore extends BaseUiStore<NewsVM> {
	name = 'NewsUiStore';
	newsStore: NewsStore;

	constructor(newsStore: NewsStore) {
		super();
		makeObservable(this);
		this.newsStore = newsStore;
	}

	@computed
	get currentKwNews() {
		const now = moment();
		return this.items.filter((x) => x.isPublishedAfter(now));
	}

	@computed
	get currentItems() {
		const now = moment();
		return this.items.filter((x) => x.isPublishedAfter(now));
	}

	@computed
	get last3Items() {
		const items = this.currentItems;
		if (!items) {
			return [];
		}
		if (items.length < 3) {
			return items;
		}
		return items.slice(0, 3);
	}

	async _baseLoad() {
		const items = await this.newsStore.findAll();
		const res: NewsVM[] = [];
		items.forEach((t) => {
			const vm = new NewsVM(t);
			res.push(vm);
		});
		runInAction(() => {
			this.items = res;
		});
	}

	async findAll() {
		await this.load();
		return this.items;
	}
}
