import { KvAgtMsModel } from 'app/models/kv.agt.ms.model';
import { computed } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

interface IKeyVal {
	key: string;
	val: number;
}


interface IAkumaPrimeModelOpts {
	aid: number;
	bm: number;
	j: string;
	key: string;
	key2: string;
	modified: number;
}
export class AkumaPrimeModel {
	[key: string]: any;

	constructor(opts: IAkumaPrimeModelOpts) {
		this.bm = opts.bm;
		this.kw = this.bm;
		this.agtId = opts.aid;

		const j: any = opts.j!;
		this.einordnung = j.einordnung;
		this.terminierung = j.zentraleNachbearbeitung;
		this.terminierungErgebnis = j.terminierung;
		this.beziehung = j.beziehung;
		this.count = j.count;
	}

	bm: number;
	kw: number;
	agtId: number;
	einordnung: string;
	beziehung: string;
	terminierung: string;
	terminierungErgebnis: string;
	count: number;
}

export class KvAgenturYearAkumaPrimeStore extends BaseCachedEntityStore<AkumaPrimeModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agentur_ms/akumaprime';

	createInstances(opts: any): AkumaPrimeModel {
		return new AkumaPrimeModel(opts);
	}
}
