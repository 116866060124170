import { action, observable, runInAction } from "mobx";
import { IAgtFilterable } from "./agt.list.filter.ui.store";

export class BaseUiStore<T> {



    name: string = 'PleaseDefineOtherWiseThisWillNotWorkOnProd';

    @observable
    items: T[] = [];



    datenStand?: number; // bm

    loadingPromise?: Promise<any>;
    loadingPromiseResolver?: any;

    initLoadinPromise() {
        this.loadingPromise = new Promise((resolve) => {
            this.loadingPromiseResolver = resolve;
        });
    }

    async _baseLoad() {
        console.log('PLEASE OVERRIDE ME!!');
    }

    async load() {
        if (this.loadingPromise) {
            await this.loadingPromise;
            return;
        }
        if (this.items.length > 0) {
            return;
        }
        // console.log('starting load' + this.name);
        console.time("LOAD " + this.name);

        this.initLoadinPromise();
        await this._baseLoad();
        if (this.loadingPromiseResolver) {
            this.loadingPromiseResolver();
        }
        console.timeEnd("LOAD " + this.name);
    };

    clearCache() {
        this.loadingPromise = undefined;
        this.loadingPromiseResolver = undefined;
        runInAction(() => {
            this.items = [];
        });
        this.customClearCache();

    }

    customClearCache() {
        // override if needed

    }

    findAndSplice(elements: any[], func: (agt: any) => Boolean) {
        const index = elements.findIndex(func);
        let obj;
        if (index !== -1) {
            obj = elements[index];
            if (obj) {
                elements.splice(index, 1);
            }
        }
        return [obj, elements];

    }

    filterAndSplice(elements: any[], func: (agt: any) => Boolean) {
        let index = elements.findIndex(func);
        let successIndex;
        let objs = [];
        let c = 0;
        while (index !== -1) {
            c++;
            const obj = elements[index];
            if (obj) {
                elements.splice(index, 1);
                objs.push(obj)
                successIndex = index - objs.length;
            }
            index = elements.findIndex(func);
        }
        // console.log(successIndex, objs.length, c, elements.length);
        return [objs, elements];

    }

    sortByAgt(items: IAgtFilterable[]) {
        return (items.sort((a, b) => a.agt.agtId - b.agt.agtId)) as T[];
    }
}
